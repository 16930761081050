import instance from './hcAxios';

export const generateToken = async () => {
  const res = await instance({
    url: '/token',
    method: 'get',
  });
  return res;
}

export const getTokenInfo = async () => {
  const res = await instance({
    url: `/token/${window.localStorage.getItem('token')}`,
    method: 'get',
  });
  return res;
}

export const fix = async (data) => {
  const res = await instance({
    url: 'restore/fix',
    method: 'post',
    data,
  });
  return res;
}

export const fixResult = async (data) => {
  const res = await instance({
    url: 'restore/fix_result',
    method: 'post',
    data,
  });
  return res;
}

export const color = async (data) => {
  const res = await instance({
    url: 'restore/color',
    method: 'post',
    data,
  });
  return res;
}

export const colorResult = async (data) => {
  const res = await instance({
    url: 'restore/color_result',
    method: 'post',
    data,
  });
  return res;
}