/*
次：9.9
天：19.9
周：29.9
月：39.9
季：69.9
年：99
*/
export const PRICES = [
  //{
  //  l1: '1年',
  //  l2: '99',
  //  l3: '299',
  //  l4: '0.27/天',
  //  badge: '推荐',
  //  v: 9900,
  //  t: 0, // 0 时长, 1 次数
  //  s: 0,
  //},
  //{
  //  l1: '3月',
  //  l2: '69.9',
  //  l3: '199',
  //  l4: '0.77/天',
  //  badge: '推荐',
  //  v: 6990,
  //  t: 0, // 0 时长, 1 次数
  //},
  //{
  //  l1: '1月',
  //  l2: '39.9',
  //  l3: '69',
  //  l4: '1.33/天',
  //  badge: '推荐',
  //  v: 3990,
  //  t: 0, // 0 时长, 1 次数
  //  s: 1,
  //},
  //{
  //  l1: '1周',
  //  l2: '29.9',
  //  l3: '49',
  //  l4: '4.27/天',
  //  badge: '推荐',
  //  v: 2990,
  //  t: 0,
  //},
  //{
  //  l1: '1天',
  //  l2: '19.9',
  //  l3: '39',
  //  l4: '限时开放',
  //  badge: '限时开放',
  //  v: 1990,
  //  t: 0,
  //  s: 2,
  //},
  {
    'l1': '1次',
    'l2': '1.98',
    'l3': '9.9',
    'l4': '限时开放',
    'badge': '限时开放',
    'v': 1,
    't': 1, // 0 时长, 1 次数
    's': 3, // 时长，0 1年，1 1月，2 一天，3 一次
    'd': 1, // 次数或时长秒
  },
   {
    'l1': '5次',
    'l2': '8.8',
    'l3': '10',
    'l4': '限时开放',
    'badge': '限时开放',
    'v': 880,
    't': 1, // 0 时长, 1 次数
    's': 3, // 时长，0 1年，1 1月，2 一天，3 一次
    'd': 5, // 次数或时长秒
   },
   {
    'l1': '10次',
    'l2': '16.8',
    'l3': '20',
    'l4': '限时开放',
    'badge': '限时开放',
    'v': 1680,
    't': 1, // 0 时长, 1 次数
    's': 3, // 时长，0 1年，1 1月，2 一天，3 一次
    'd': 10, // 次数或时长秒
   },
   {
    'l1': '100次',
    'l2': '99',
    'l3': '200',
    'l4': '限时开放',
    'badge': '最划算',
    'v': 9900,
    't': 1, // 0 时长, 1 次数
    's': 3, // 时长，0 1年，1 1月，2 一天，3 一次
    'd': 100, // 次数或时长秒
   },
];