import { ReactComponent as IconWXZF } from '@assets/imgs/icon-wxzf.svg';
import { ReactComponent as IconZFBZF } from '@assets/imgs/icon-zfbzf.svg';
import { ReactComponent as IconCha } from '@assets/imgs/icon-x.svg';
import { ReactComponent as IconVIP } from '@assets/imgs/icon-vip.svg';
import { Modal } from 'antd';
import { useState, useEffect, useRef } from 'react';
import bus from '@utils/bus';
import { EVENTS } from '@constants/bus';
import { getUserInfo } from '@utils/user';
import QRCode from 'react-qr-code';
import { useMutation, useQuery } from 'react-query';
import { orderStatus, getVIPConfig } from '@services/user';
import { VIP as VIPTYPES } from '@constants/common';
//import { PRICES } from './const';

export const vipstr = (vip_type) => {
  switch (vip_type) {
    case VIPTYPES.FREE:
      return '普通会员';
    case VIPTYPES.TIMES:
      return 'VIP会员';
    case VIPTYPES.DURATION:
      return '尊享会员';
  }
  return '';
}

export default function VIP() {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const userInfo = getUserInfo();
  const [no, setNo] = useState(CryptoJS.MD5(`${userInfo.openid}_${new Date().getTime()}`).toString().toUpperCase())
  const { mutate } = useMutation(orderStatus);
  const { data: PRICES } = useQuery('getVIPConfig', getVIPConfig);
  const ref = useRef(0);

  useEffect(() => {
    function openModal() {
      setOpen(true);
    }
    bus.on(EVENTS.VIP, openModal);
    return () => {
      bus.removeListener(EVENTS.VIP, openModal);
    }
  }, []);

  useEffect(() => {
    const no = CryptoJS.MD5(`${userInfo.openid}_${new Date().getTime()}`).toString().toUpperCase();
    setNo(no);
    if (open) {
      if (ref.current) {
        clearInterval(ref.current);
      }
      ref.current = setInterval(() => {
        mutate(no, {
          onSuccess: (res) => {
            // 支付成功
            if (res?.data?.status == 'paid') {
              // 刷新页面
              history.go(0);
            }
          },
        });
      }, 3000);
    } else {
      clearInterval(ref.current);
    }
    return () => clearInterval(ref.current);
  }, [open]);

  return (
    <Modal width={900} open={open} footer={null} closeIcon={null} centered={true} classNames={{ wrapper: 'dialog-pay', content: 'no-padding' }}>
      <div class="pay-wrapper">
        <div class="pay-top">
          <div class="pay-top__avatar" style={{ background: `url('${userInfo.avatar}') center center / cover no-repeat` }}></div>
          <div class="pay-top__uname">{userInfo.nickname}</div>
          <div class="pay-top__lvl">{vipstr(userInfo.vip_type)}</div>
          <span role="img" tabindex="-1" class="anticon pay-top__close" onClick={() => setOpen(false)}>
            <IconCha />
          </span>
        </div>
        <div class="pay-body">
          {/*<div class="pay-aside is-pay-block">
            <div class="pay-aside__top">
              <span role="img" class="anticon pay-aside__top-icon">
                <IconVIP />
              </span>
              <span class="pay-aside__top-txt">成为会员即可享受</span></div>
            <ul class="pay-aside__list">
              <li class="is-color-r">PPT可编辑源文件下载</li>
              <li class="">支持导出图片/PDF</li>
              <li class="is-color-r">Word文档导入转PPT</li>
              <li class="is-color-r">PDF文档导入转PPT</li>
              <li class="">TXT文档导入转PPT</li>
              <li class="">Xmind文档导入转PPT</li>
              <li class="">Freemind导入转PPT</li>
              <li class="">Markdown导入转PPT</li>
              <li class="">思维导图大纲下载</li>
              <li class="">海量精品模板随意调用</li>
              <li class="">单页排版样式随意替换</li>
              <li class="">自由更换PPT配色</li>
              <li class="">无水印在线编辑与导出</li>
              <li class="is-color-r">PPT模板个人商用授权</li>
            </ul>
          </div>*/}
          <div class="pay-main">
            <div class="vip-type is-pay-block" style={{ marginBottom: '16px' }}>
              <div class="vip-type__title base-title">会员类型</div>
              <div class="vip-type__item active-item">标准VIP</div>
            </div>
            <div class="vip-product is-pay-block">
              <div class="vip-product__title base-title">购买次数</div>
              <div class="vip-product__list">
                {PRICES?.map((item, index) => (
                  <div
                    key={index}
                    className={['product-item', currentIndex === index ? 'active-item' : ''].join(' ')}
                    onClick={() => setCurrentIndex(index)}
                  >
                    <div class="product-item__name">{item.l1}</div>
                    <div class="product-item__price">
                      <span>¥</span>
                      <strong>{item.l2}</strong></div>
                    <div class="product-item__oprice">原价
                      <span>¥{item.l3}</span></div>
                    <div class="product-item__explain">{item.l4}</div>
                    <div class="product-item__hangtag">{item.badge}</div>
                  </div>
                ))}
              </div>
            </div>
            <div data-v-0702d5f4="" class="vip-order is-pay-block">
              <div data-v-0702d5f4="" class="order-top">
                <div data-v-0702d5f4="" class="base-title">支付订单 {no}</div>
              </div>
              <div data-v-0702d5f4="" class="order-payment">
                <div data-v-0702d5f4="" class="order-qrcode">
                  {!!PRICES && (
                    <QRCode
                      size={102}
                      value={`https://hc.ppai.cn/wxpay?openid=${userInfo.openid}&t=${PRICES?.[currentIndex]?.t}&s=${PRICES?.[currentIndex]?.s}&d=${PRICES?.[currentIndex]?.d}&no=${no}`}
                      viewBox="0 0 512 512"
                    />
                  )}
                </div>
                <div data-v-0702d5f4="" class="order-desc">
                  <div data-v-0702d5f4="" class="order-desc__price">
                    <label data-v-0702d5f4="" class="base-title">实付:</label>
                    <span data-v-0702d5f4="" class="is-color-r">
                      <strong data-v-0702d5f4="">{PRICES?.[currentIndex]?.l2}</strong>元</span>
                  </div>
                  <div data-v-0702d5f4="" class="order-desc__text">使用&nbsp;&nbsp;
                    <span data-v-0702d5f4="" role="img" class="anticon">
                      <IconWXZF />
                    </span>&nbsp;&nbsp;微信扫码支付
                    {/*<span data-v-0702d5f4="" role="img" class="anticon" style={{ color: 'rgb(0, 160, 234)' }}>
                      <IconZFBZF />
                    </span>支付宝扫码支付*/}
                  </div>
                  <div data-v-0702d5f4="" class="order-desc__text">支付即视为你同意
                    <a data-v-0702d5f4="" href="/help/vip-agreement" target="_blank">《PPAI会员协议》</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
