import axios from 'axios';
import { goHomepage } from '@utils/login';

export const API_DOMAIN =  'https://api.hc.ppai.cn/api';

const instance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: false,
  baseURL: API_DOMAIN,
});

instance.interceptors.request.use(
  (config) => {
    config.headers = {
      token: window.localStorage.getItem('token'),
      ...config.headers,
    };
    return config;
  },
  (err) => Promise.reject(err),
);

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.code) {
      if (response?.data?.code === 401) {
        goHomepage();
      }
    }
    return response.data;
  },
  (err) => {
    if (err.isAxiosError) {
      console.log(err);
    }
    if (401 === err?.response?.status) {
      window.localStorage.clear();
      goHomepage();
    }
    return Promise.reject(err);
  },
);

export default instance;
