import instance from './axios';

export const getUserInfoService = async (openid) => {
  const res = await instance({
    url: '/wx/userinfo',
    method: 'get',
    params: { openid },
  });
  return res;
};

export const getUserInfoServiceForWX = async (openid) => {
  const res = await instance({
    url: '/wx/openid',
    method: 'get',
    params: { openid },
  });
  return res;
};

export const getVIPConfig = async () => {
  const res = await instance({
    url: '/vip/config',
    method: 'get',
  });
  return res;
};

export const getAiPPTCode = async (openid) => {
  const res = await instance({
    url: '/aippt/code',
    method: 'get',
    params: { openid },
  });
  return res;
};

export const genOnce = async (openid) => {
  const res = await instance({
    url: '/aippt/gen',
    method: 'get',
    params: { openid },
  });
  return res; 
}

export const order = async (params) => {
  const res = await instance({
    url: '/wx/order',
    method: 'get',
    params,
  });
  return res; 
}

export const orderStatus = async (no) => {
  const res = await instance({
    url: '/wx/order/status',
    method: 'get',
    params: { no },
  });
  return res; 
}