import React from 'react';
import OriginImage from '@assets/imgs/origin.png';
import RestoredImage from '@assets/imgs/restore_fix_color.png';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import Style from './index.module.scss';
import Logo from '@assets/imgs/logo.jpg';
import { Spin, Upload } from 'antd';
import Footer from '@pages/homepage/footer';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { fix, fixResult, color, colorResult } from '@services/ppai';
import Login from '@components/Login';
import { hasLogin, login } from '@utils/login';
import Header from '@components/Header';
import VIP from '@components/VIP';
import { getUserInfo } from '@utils/user';
import bus from '@utils/bus';
import { EVENTS } from '@constants/bus';
import { genOnce } from '@services/user';

const { Dragger } = Upload;

function Index() {
  const [status, setStatus] = useState('init');
  const [step, setStep] = useState('idle');
  const [originImage, setOriginImage] = useState(OriginImage);
  const [restoredImage, setRestoredImage] = useState(RestoredImage);
  const { mutate: doFix } = useMutation(fix);
  const { mutate: fetchFixResult } = useMutation(fixResult);
  const { mutate: doColor } = useMutation(color);
  const { mutate: fetchColorResult } = useMutation(colorResult);
  const userInfo = getUserInfo();
  const { mutate } = useMutation(genOnce);

  const uploadProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    action: 'https://api.hc.ppai.cn/api/common/upload',
    onChange(info) {
      if (!hasLogin()) {
        setStatus('init');
        return;
      }
      if (userInfo.expires < 1) {
        setStatus('init');
        return;
      }
      const { status } = info.file;
      setStatus('uploading');
      if (status === 'done') {
        const url = info.file.response.data;
        if (url !== null && url.length > 0) {
          restore(url);
        }
        setStatus('init');
      } else if (status === 'error') {
        setStatus('init');
      }
    },
    beforeUpload() {
      if (!hasLogin()) {
        login();
        return false;
      }
      if (userInfo.expires < 1) {
        bus.emit(EVENTS.VIP);
        return false;
      }
      return true;
    },
  }

  function restore(url) {
    setStep('uploaded');
    setOriginImage(url);
    setRestoredImage(url);
    // 次数减一
    userInfo.expires = parseInt(userInfo.expires, 10) - 1;
    window.localStorage.setItem('USERINFO', JSON.stringify(userInfo));
    bus.emit(EVENTS.UPDATE_USERINFO);
    mutate(userInfo.openid);
    setTimeout(() => {
      _doFix(url);
    }, 2000);
  }

  function _doFix(url) {
    doFix({ url }, {
      onSuccess: (res) => {
        const token = res.data;
        _fixResult(token);
      },
      onError: (e) => {
        console.log(e);
        setTimeout(() => _doFix(url), 5000);
      }
    });
  }

  function _fixResult(token) {
    fetchFixResult({ token }, {
      onSuccess: (res) => {
        const fixedUrl = res.data;
        if (fixedUrl) {
          setRestoredImage(fixedUrl);
          setStep('fixed');
          _color(fixedUrl);
        } else {
          setTimeout(() => _fixResult(token), 1000);
        }
      },
      onError: (e) => {
        console.log(e);
        setTimeout(() => _fixResult(token), 5000);
      }
    })
  }

  function _color(url) {
    doColor({ url }, {
      onSuccess: (res) => {
        const token = res.data;
        _colorResult(token);
      },
      onError: (e) => {
        console.log(e);
        setTimeout(() => _color(url), 5000);
      }
    });
  }

  function _colorResult(token) {
    fetchColorResult({ token }, {
      onSuccess: (res) => {
        const colorUrl = res.data;
        if (colorUrl) {
          setRestoredImage(colorUrl);
          setStep('done');
        } else {
          setTimeout(() => {
            _colorResult(token)
          }, 1000);
        }
      },
      onError: (e) => {
        console.log(e);
        setTimeout(() => _colorResult(token), 5000);
      }
    })
  }

  function download() {
    fetch(restoredImage)
      .then(res => res.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        var url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = "幻彩修复.png";
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
  }

  return (
    <>
      <Header />
      <div className={Style.logo}>
        <img src={Logo} alt="logo" className={Style.logoImg} />
        <div className={Style.name}>幻彩 - AI 照片修复</div>
        <Dragger {...uploadProps} disabled={status !== 'init'}>
          <div className="button-container" data-v-fbaa4b64="">
            <button className="button" data-v-fbaa4b64="">
              {status === 'init' && '开始 AI 修复'}
              {status == 'uploading' && <Spin />}
            </button>
          </div>
        </Dragger>
      </div>
      <div className={Style.demo}>
        <Spin spinning={!['idle', 'done'].includes(step)} size="large">
          <ReactCompareSlider
            className={Style.container}
            itemOne={<ReactCompareSliderImage className={Style.img} src={originImage} alt="Origin image" />}
            itemTwo={<ReactCompareSliderImage className={Style.img} src={restoredImage} alt="Restored image" />}
            onlyHandleDraggable={true}
          />
        </Spin>
      </div>
      {step === 'done' && (
        <center>
          <div className="button-container" data-v-fbaa4b64="" onClick={download}>
            <button className="button" data-v-fbaa4b64="">
              下载
            </button>
          </div>
        </center>
      )}

      <div class="layout-content" data-v-7b9bb84e="">
        <section data-v-458aab05="">
          <div class="page-content end-section-content" data-v-458aab05="" data-v-9f8a4cfc="">
            <h2 class="section-title" data-v-458aab05="">联系我们</h2>
            <h2 class="section-title-en" data-v-458aab05="">Contact Us</h2>
            <div class="content" data-v-458aab05="">
              <img src="/_nuxt/hands-shake.dac34c79.webp" class="img" data-v-458aab05="" />
              <div class="texts" data-v-458aab05="">
                <img src="/_nuxt/logo_cn.2218c6ed.png" class="logo" data-v-458aab05="" />
                <div class="inner" data-v-458aab05="">
                  <div data-v-458aab05="">
                    <p data-v-458aab05="">服务热线：13261811919</p>
                    <p data-v-458aab05="">公司地址：惠州仲恺高新区陈江街道金茂路北一街25号207</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Login />
      <VIP />
      <Footer />
    </>
  );
}

export default Index;
