import Header from '@components/Header';
import VIP from '@components/VIP';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function VIPAgreement() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.href = '/index.3b49210b.css';
    document.head.appendChild(style);
    setTimeout(() => setLoading(false), 500);
    return () => style.remove();
  }, []);

  if (loading) return null;

  return (
    <>
      <Header mute={true} />
      <VIP />
      <main data-v-50ade72f="" data-v-3469ecbf="" class="main">
        <div data-v-50ade72f="" class="body">
          <div data-v-50ade72f="" class="content layout-content">
            <div data-v-3469ecbf="" class="inner">
              <ul data-v-1f6b9767="" data-v-3469ecbf="" class="menus" style={{ marginRight: '28px' }}>
                <Link to="/help/license">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">授权许可协议</span>
                  </li>
                </Link>
                <Link to="/help/privacy-policy">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">隐私政策</span>
                  </li>
                </Link>
                <Link to="/help/user-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">用户协议</span>
                  </li>
                </Link>
                <Link to="/help/vip-agreement">
                  <li data-v-1f6b9767="" class="menu-item current">
                    <span data-v-1f6b9767="">VIP 会员协议</span>
                  </li>
                </Link>
                <Link to="/help/ai-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">AI 服务协议</span>
                  </li>
                </Link>
              </ul>
              <section data-v-37857998="" data-v-3469ecbf="" class="common-wrapper">
                <article>
                  <h1>PPAI VIP会员协议</h1>
                  <p>欢迎使用惠州新之源文化传播有限公司旗下产品PPAI的会员服务，为了保障您的权益，请在进行下一步操作前，详细阅读本协议的所有内容，特别是加粗部分。请您仔细阅读、理解并遵守《PPAI VIP会员协议》（以下简称“本协议”）内容。本协议由您与PPAI会员服务提供方共同缔结，具有合同效力。
                    <br /></p>
                  <p>本协议内容包括本协议正文、PPAI已经发布或将来可能发布的与PPAI会员服务相关的各类规则。所有规则为本协议不可分割的组成部分，与本协议具有同等法律效力。一旦相关内容发生变动，PPAI将会通过站内信等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务，则视为您同意对本协议内容所做的修改。</p>
                  <h3>一、定义</h3>
                  <p>1.PPAI VIP会员：指注册用户在签署本协议并根据PPAI公布的收费标准支付相应的费用后获取的特殊资格，具体资格名称以PPAI公示的名称为准。以下简称“会员”。</p>
                  <p>2.会员服务：指会员所享有根据选择购买的会员选项所享有的特殊服务，具体以PPAI公布的服务内容为准。</p>
                  <h3>二、使用规范</h3>
                  <p>1.您申请开通会员服务时，需要提交您本人的手机号码、邮箱及第三方支付账户等个人资料，并保证您提供的个人资料真实、准确、完整、合法有效。如您提供的资料不合法、不真实、不准确、不详尽，由此引起的损失及法律责任将由您自行承担，给PPAI造成损失的，PPAI保留要求您赔偿的权利。</p>
                  <p>2.PPAI有权单方对其提供的虚拟产品(包括但不限于VIP会员)予以定价，并有权根据自身运营需要予以变更，且PPAI在此获得您的授权可以根据平台统一调整比例调整您已经获取的虚拟产品;基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金亦不得用于商业领域如买卖、置换、抵押等。一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，PPAI有权中止或终止对您的服务。</p>
                  <p>3.会员服务有效期根据您选择购买的会员选项的具体时长为准，自您成功支付会员服务费用之日起计算。每一个收费项目的服务周期以页面标注期限为准。如您未在有效时间内使用已购买的服务，视为您已使用，PPAI概不退款。 若您希望在有效期届满后继续享受会员服务，则需要重新购买。</p>
                  <p>4.PPAI会员周期说明：1个月会员=30天、季享会员=90天、年度会员=365天、两年会员=730天。</p>
                  <p>5.PPAI会员通过AI自动生成PPT次数：30次/天。PPAI会员-模板广场下载+编辑次数：30次/天。</p>
                  <p>6.在使用会员服务过程中，您应当是具备与您行为相适应的民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请务必在您的监护人的帮助下共同完成，若因此使得PPAI或任何第三方权益受损，您及您的监护人将承担相应的责任。</p>
                  <p>7.您知悉并同意，PPAI有权通过站内信、邮件、短信或电话等形式， 向您发送会员活动相关信息。</p>
                  <p>8.您确认会员服务仅限您本人使用，同时，您保证您将合理使用会员服务，不利用会员服务非法获利，不以任何形式转让您所享有的会员服务，不以任何形式将您所享有的会员服务借给他人使用，如PPAI有合理理由怀疑您存在不当使用会员服务时，PPAI有权取消您的会员资格且不退还您支付的会员服务费用，因此产生的相关责任及损失均由您自行承担，给PPAI造成损失的，PPAI保留向您追偿的权利。</p>
                  <p>9.PPAI网保留在法律法规允许的范围内自行决定是否接受您的会员申请、调整会员服务内容、取消会员资格等相关权利。</p>
                  <p>10.您理解并保证，您在使用会员服务过程中遵守诚实信用原则。如PPAI发现或有合理理甶怀疑您存在以下任一情形的：
                    <br/>(a) 通过任何不当手段或以违反诚实信用原则的方式开通会员服务的，包括但不限于通过恶意软件绕过PPAI设定的正常流程开通会员服务；
                      <br/>(b) 您提供的资料不合法、不真实，包括但不限于盗用他人信息；
                        <br/>(c) 您通过PPAI会员专区购买的商品用于法律法规所禁止的目的；
                          <br/>(d) PPAI有合理理由怀疑您存在违反诚实信用原则的其他行为。 则PPAI有权拒绝您的会员服务开通需求；若已开通的，PPAI有权单方面取消您的会员资格且不退还您支付的会员服务费用。</p>
                          <p>11.您理解并同意PPAI有权根据实际业务需要不时地调整其网站内容而无需另行通知您，包括但不限于内容增减、授权期限、收费模式等等。</p>
                          <h3>三、账号独立</h3>
                          <p>1.同一主体拥有多个平台支持的第三方注册账号，在进行PPAI VIP登录时，将被识别为多个独立的帐号；（使用某一个平台注册的账号并绑定其他平台账号的除外）</p>
                          <p>2.每个帐号之间的充值记录、VIP权限无法进行转移、迁徙、转让、赠与、售卖、租借、分享，无论该帐号是否由同一使用人拥有，请您在登录/充值/参加活动时注意区分，避免造成损失。由此造成的损失由VIP会员自行承担。</p>
                          <h3>四、内容授权使用范围</h3>
                          <p>1.本协议的内容许可使用为单一使用许可，仅许可一位自然人下载和使用PPT模板及素材内容，但不可权利转让、且无任何版权授权、本协议的所有内容仅适用于个人非商业使用，您购买VIP服务所提供的模板以及其中字体、摄影图、素材、音乐能够在所提供的模板内使用，您不能够将字体、摄影图、素材、音乐与PPAI所提供的模板进行拆分、剥离或者单独进行二次创作，PPAI仅许可您在PPAI提供的模板内使用字体、摄影图、素材、音乐。若您违反上述规定，导致侵犯第三方版权、由此产生的一切赔偿责任，将由您承担。</p>
                          <p>2.本协议项下的授权仅对PPAI网站提供的PPAI内容有效，其他任何途径的内容及其生成的设计成品均不受本协议的保护。</p>
                          <p>3.本授权仅限您自身使用，您不得将PPAI提供内容、设计成品等用于向第三方授权、转让、共享、租售等；不得允许将PPAI提供内容、设计成品以任何形式（包括独立文件形式）被第三方下载、提取、解压缩、再分销；也不得将PPAI提供的内容、设计成品用于PPAI的竞争业务中。</p>
                          <p>4.您不得违反或超出本协议的授权使用PPAI提供内容及设计成品，包括不得超出授权范围、授权权限、授权时间等。</p>
                          <p>5.您制作并下载设计成品后，在PPAI的设计模板编辑器外对设计成品进行增添、删减等任何修改行为，PPAI概不负责。</p>
                          <p>6、您不得违反法律法规使用PPAI提供内容或设计成品，包括但不限于不得将PPAI提供的内容用于欺骗性广告、不正当竞争以及任何侵犯他人合法权益的情形。</p>
                          <p>7、您不得将PPAI提供的内容及设计成品用于烟草、酒精、药物、整容、医疗保健产品等敏感行业的广告或推广项目。</p>
                          <p>8、您不得对PPAI提供内容及其中的人物肖像（如有）以冒犯、侵权、违法等不当方式使用，不当方式包括但不限于：</p>
                          <p>（1）在色情图像或类似情形中使用；</p>
                          <p>（2）用于暗示模特参与不道德或非法行为；</p>
                          <p>（3）用于暗示模特遭受身体或精神衰弱、疾病等状况;</p>
                          <p>（4）用于政治性代言的行为；</p>
                          <p>（5）其他对人物有冒犯或贬低的行为。</p>
                          <p>9、您不得将PPAI提供的内容制成转售商品，如模板、壁纸、手机壳、贺卡、明信片、日历、杯子、T恤等。</p>
                          <p>10、您不得将PPAI提供的内容用于楼宇、车身、灯箱、橱窗等户外广告。</p>
                          <p>11、您不得将PPAI提供的内容用于任何商标、商号、商业名称、外观设计专利、产品包装、服务标志或标识等。</p>
                          <p>12、如您对上述第9、10、11项有特殊需求，可联系PPAI进行专属定制。</p>
                          <h3></h3>
                          <h3>五、会员服务售后</h3>
                          <p>1.您知悉并确认，开通会员服务后，若您中途主动取消服务或终止资格或被PPAI根据《PPAI VIP服务协议》、本协议及相关规则注销账号、终止会员资格的，您已支付的会员服务费用将不予退还。</p>
                          <p>2.如您有其他与会员服务售后相关的问题，可以通过PPAI公布的联系方式联系客服进行反馈。</p>
                          <p>3.正在进行的平台交易，使用等，将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。</p>
                          <h3>六、其他约定</h3>
                          <p>1.通知：所有发给您的通知都可通过站内信、电子邮件、常规的信件或在网站显著位置公告的方式进行传送。</p>
                          <p>2.本协议适用中华人民共和国的法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关条款将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。</p>
                          <p>3.如使用会员服务过程中出现纠纷，您与PPAI应友好协商解决，本协议最终解释权归PPAI所有。</p>
                        </article>
                      </section>
                    </div>
                  </div>
                </div>
              </main>
            </>
            );
}
