import "./App.css";
import AppRoutes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  }
});

function App() {
  return (
    <div className="App" id="app">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <AppRoutes />
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
