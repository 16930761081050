import { ReactComponent as IconWX } from '@assets/imgs/icon-wx.svg';
import { ReactComponent as IconCha } from '@assets/imgs/icon-x.svg';
import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import bus from '@utils/bus';
import { EVENTS } from '@constants/bus';
import { useMutation } from 'react-query';
import { qrcode, checkLogin } from '@services/login';
import { hasLogin } from '@utils/login';

function Login() {
  const [open, setOpen] = useState(false);
  const loggedIn = hasLogin();
  const [data, setData] = useState({});
  const { mutate: getQrcode } = useMutation(qrcode);
  const { mutate: loopCheckLogin } = useMutation(checkLogin)

  useEffect(() => {
    function openModal() {
      setOpen(true);
    }
    bus.on(EVENTS.LOGIN, openModal);
    return () => {
      bus.removeListener(EVENTS.LOGIN, openModal);
    }
  }, []);

  useEffect(() => {
    if (!open) return;
    if (loggedIn) return;
    getQrcode(null, {
      onSuccess: (res) => {
        setData(res);

        function tmp() {
          loopCheckLogin(res.uuid, {
            onSuccess: (res) => {
              if (res?.code === 0) {
                window.localStorage.setItem('UUID', res.uuid);
                window.localStorage.setItem('USERINFO', JSON.stringify(res.data));
                setTimeout(() => {
                  bus.emit(EVENTS.LOGGEDIN);
                  setOpen(false);
                }, 500);
                history.go(0);
              } else {
                window.setTimeout(tmp, 2000);
              }
            },
          })
        }
        tmp();

      },
    });
  }, [open]);

  return (
    <Modal open={open} footer={null} closeIcon={null} centered={true} classNames={{ wrapper: 'dialog-login-home', content: 'no-padding' }}>
      <div class="dialog-login-container">
        <img
          class="dialog-login-logo"
          src="/_nuxt/logo_cn.2218c6ed.png"
          alt=""
        />
        <div class="dialog-login-close" onClick={() => setOpen(false)}>
          <span role="img" class="anticon iconfont icon-cha">
            <IconCha />
          </span>
        </div>
        <div data-v-b0169ef6="" class="dialog-login-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div data-v-b0169ef6="" class="dialog-login-content-inner">
            <h1 data-v-b0169ef6="" class="dialog-login-title">
              <div data-v-b0169ef6="" class="dialog-login-title-icon wechat">
                <span data-v-b0169ef6="" role="img" class="anticon iconfont icon-weixin">
                  <IconWX />
                </span>
              </div>微信登录</h1>
            <div data-v-b0169ef6="" class="qrcode-wrapper">
              <div data-v-b0169ef6="" class="qrcode" id="login_container">
                {data?.url && <img data-v-b0169ef6="" src={data?.url} alt="" />}
              </div>
              <p data-v-b0169ef6="">请使用手机微信扫码登录</p></div>
          </div>
        </div>
        <div class="dialog-login-footer">登录注册即表示同意
          <a href="/help/user-agreement" target="_blank">用户协议</a>
          <a href="/help/privacy-policy" target="_blank">隐私政策</a></div>
      </div>
    </Modal>
  );
}

export default Login;
