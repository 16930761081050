export const formatTimestamp = (str) => {
  if (!str) {
    return '-';
  }
  const date = new Date(str * 1000);
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = ('0' + date.getDate()).substr(-2) + ' ';
  const h = ('0' + date.getHours()).substr(-2) + ':';
  const m = ('0' + date.getMinutes()).substr(-2) + ':';
  const s = ('0' + date.getSeconds()).substr(-2);
  return Y + M + D + h + m + s;
}

export const formatVIPExpires = (str) => {
  if (!str) {
    return '-';
  }
  const date = new Date(str * 1000);
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = ('0' + date.getDate()).substr(-2) + ' ';
  return Y + M + D;
}