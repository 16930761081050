export default function Footer() {
  return (
    <div data-v-9f8a4cfc="" data-v-bd2a8e4a="" data-v-7b9bb84e="" class="page-content">
      <div data-v-bd2a8e4a="" class="footer">
        <ul data-v-bd2a8e4a="" class="horizon-links">
          <li data-v-bd2a8e4a="" class="link">
            <a data-v-bd2a8e4a="" href="/about-us" target="_self">公司介绍</a>
          </li>
          <li data-v-bd2a8e4a="" class="link">
            <a data-v-bd2a8e4a="" href="/help/license" target="_blank">授权许可协议</a>
          </li>
          <li data-v-bd2a8e4a="" class="link">
            <a data-v-bd2a8e4a="" href="/help/privacy-policy" target="_blank">隐私政策</a>
          </li>
          <li data-v-bd2a8e4a="" class="link">
            <a data-v-bd2a8e4a="" href="/help/user-agreement" target="_blank">用户协议</a>
          </li>
          <li data-v-bd2a8e4a="" class="link">
            <a data-v-bd2a8e4a="" href="/help/vip-agreement" target="_blank">VIP 会员协议</a>
          </li>
          <li data-v-bd2a8e4a="" class="link">
            <a data-v-bd2a8e4a="" href="/help/ai-agreement" target="_blank">AI 服务协议</a>
          </li>
        </ul>
        <div data-v-bd2a8e4a="" class="middle">
          <div data-v-bd2a8e4a="" class="friend">
            <span data-v-bd2a8e4a="" style={{ whiteSpace: 'nowrap', flexShrink: 0 }}>友情链接：</span>
            <div data-v-bd2a8e4a="" class="links friend-link">
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://openi.cn" style={{ marginBottom: '4px', marginRight: '40px' }}>OpenI AI 导航</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.aigc.cn/" style={{ marginBottom: '4px', marginRight: '40px' }}>AIGC 导航</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.51pptmoban.com/" style={{ marginBottom: '4px', marginRight: '40px' }}>51PPT 模板网</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://qkunai.com/" style={{ marginBottom: '4px', marginRight: '40px' }}>乾坤 AI</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://ai-bot.cn/" style={{ marginBottom: '4px', marginRight: '40px' }}>AI 工具集</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.deepdhai.com/" style={{ marginBottom: '4px', marginRight: '40px' }}>深度 AI 导航</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.aizj.cc/" style={{ marginBottom: '4px', marginRight: '40px' }}>AI 之家</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.aidashi.cn/" style={{ marginBottom: '4px', marginRight: '40px' }}>AI 大师</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.ainav.cn/" style={{ marginBottom: '4px', marginRight: '40px' }}>AI 导航网</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://17yongai.com/" style={{ marginBottom: '4px', marginRight: '40px' }}>一起用 AI</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.huntagi.com" style={{ marginBottom: '4px', marginRight: '40px' }}>未来百科AI导航</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.365editor.com" style={{ marginBottom: '4px', marginRight: '40px' }}>365 编辑器</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.ppter8.com/" style={{ marginBottom: '4px', marginRight: '40px' }}>PPTer 吧</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.ppthui.com" style={{ marginBottom: '4px', marginRight: '40px' }}>PPT汇</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://hao.logosc.cn/" style={{ marginBottom: '4px', marginRight: '40px' }}>神器集</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://nav.guidebook.top/" style={{ marginBottom: '4px', marginRight: '40px' }}>数字生活指南</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.shenmezhidedu.com" style={{ marginBottom: '4px', marginRight: '40px' }}>好书推荐</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.weiling.cn" style={{ marginBottom: '4px', marginRight: '40px' }}>卫瓴·协同CRM</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.yjpoo.com" style={{ marginBottom: '4px', marginRight: '40px' }}>映技派</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.moxueyuan.com/?channeltag=aippt-1" style={{ marginBottom: '4px', marginRight: '40px' }}>魔学院·企业培训平台</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.xinrenxinshi.com/" style={{ marginBottom: '4px', marginRight: '40px' }}>薪人薪事</a>
              <a data-v-bd2a8e4a="" rel="noreferrer" target="_blank" class="link" href="https://www.iai88.com/" style={{ marginBottom: '4px', marginRight: '40px' }}>爱AI导航</a>
            </div>
          </div>
          <div data-v-bd2a8e4a="" class="extra-container">
            <p data-v-bd2a8e4a="" class="extra-info">地址：惠州仲恺高新区陈江街道金茂路北一街25号207 联系电话：13261811919</p>
            <p data-v-bd2a8e4a="" class="extra-info">
              <span data-v-bd2a8e4a="">Copyright©惠州新之源文化传播有限公司</span>
              <a data-v-bd2a8e4a="" href="#" target="_blank">&nbsp;&nbsp;Version-0.0.1</a>
            </p>
            <div data-v-bd2a8e4a="" class="extra-info">
              <img alt="" data-v-bd2a8e4a="" src="/_nuxt/police-badge.a20583c8.png" />
              <a data-v-bd2a8e4a="" rel="noreferrer" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2023072319号</a></div>
          </div>
        </div>
      </div>
    </div>
  );
}
