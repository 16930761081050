import bus from '@utils/bus';
import { EVENTS } from '@constants/bus';
import { hasLogin } from '@utils/login';

export function vip() {
  bus.emit(EVENTS.VIP);
}

export function getUserInfo() {
  if (!hasLogin()) return {};
  return JSON.parse(window.localStorage.getItem('USERINFO'));
}
