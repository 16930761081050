import { ReactComponent as IconVip } from '@assets/imgs/icon-vip.svg';
import { hasLogin, login, goHomepage } from '@utils/login';
import bus from '@utils/bus';
import { EVENTS } from '@constants/bus';
import { VIP } from '@constants/common';
import { getUserInfo } from '@utils/user';
import { useEffect, useState } from 'react';
import { notification, Dropdown } from 'antd';
import { formatVIPExpires } from '@utils/formatter';
import { getUserInfoService } from '@services/user';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

export default function Header({ mute = false }) {
  const [loggedIn, setLoggedIn] = useState(hasLogin());
  const [userInfo, setUserInfo] = useState(getUserInfo());
  const { mutate } = useMutation(getUserInfoService);

  const genCreditsNumber = () => {
    if (!loggedIn) return '';
    if ([VIP.FREE, VIP.TIMES].includes(userInfo?.vip_type)) {
      return userInfo?.expires;
    }
    return '';
  };

  useEffect(() => {
    function tmp() {
      setUserInfo(getUserInfo());
      setLoggedIn(hasLogin());
    }
    bus.on(EVENTS.LOGGEDIN, tmp);
    bus.on(EVENTS.UPDATE_USERINFO, tmp);
    return () => {
      bus.removeListener(EVENTS.LOGGEDIN, tmp);
      bus.removeListener(EVENTS.UPDATE_USERINFO, tmp);
    };
  }, [])

  useEffect(() => {
    if (!loggedIn) return;
    mutate(userInfo.openid, {
      onSuccess: (res) => {
        window.localStorage.setItem('USERINFO', JSON.stringify(res.data));
        setUserInfo(res.data);
        if (!mute) {
          const userInfo = res.data;
          notification.config({ top: 60 });
          let desc = '您当前是';
          if (userInfo.vip_type === VIP.FREE) {
            desc += '普通会员; 您还有' + userInfo.expires + '次免费试用机会';
          }
          if (userInfo.vip_type === VIP.TIMES) {
            desc += 'VIP会员; 您还有' + userInfo.expires + '次使用权利';
          }
          if (userInfo.vip_type === VIP.DURATION) {
            desc += '尊享会员; 您的会员到期时间为:' + formatVIPExpires(userInfo.expires);
          }
          notification.success({
            duration: 4.5,
            placement: 'topRight',
            message: 'PPAI 皮皮智创',
            description: desc,
          });
        }
      },
    })
  }, [loggedIn]);

  return (
    <header class="header" data-v-7b9bb84e="" data-v-32c18e0c="">
      <a data-v-b02ba721="" data-v-32c18e0c="" className="logo" href="/" target="_self">
        <img alt="" data-v-b02ba721="" src="/_nuxt/logo_cn.2218c6ed.png" style={{ width: '70%' }} />
      </a>
      <div data-v-32c18e0c="" class="header-right">
        <div data-v-abca80c7="" data-v-32c18e0c="" class="nav">
          {/*<div data-v-abca80c7="" class="item" href="/template?from=workspace" target="_blank">模板广场</div>
          <div data-v-abca80c7="" class="item" href="https://open.aippt.cn/" target="_blank">开放平台</div>
          <div data-v-abca80c7="" class="item" href="/download?from=workspace" target="_self">下载客户端</div>
          <div data-v-abca80c7="" class="item" href="/price?from=workspace" target="_self">权益对比</div>*/}
        </div>
        {!loggedIn && (
          <div data-v-32c18e0c="" class="buttons">
            <button data-v-32c18e0c="" class="register-button" onClick={login}>免费注册</button>
            <button data-v-32c18e0c="" class="login-button" onClick={login}>登录</button>
          </div>
        )}
        {loggedIn && (
          <>
            <button data-v-32c18e0c="" class="vip-button" onClick={() => bus.emit(EVENTS.VIP)}>
              <IconVip />
              <span data-v-32c18e0c="">{userInfo?.vip_type === VIP.FREE ? '开通' : '续费'} VIP</span>
            </button>
            <Dropdown menu={{ items: [
              {
                key: 'logout',
                label: <span onClick={() => { window.localStorage.clear(); goHomepage(); }}>退出登录</span>
              }
            ] }}>
              <div data-v-06a2de27="" data-v-0ada1f42="" data-v-32c18e0c="" class="base-dropdown-root dropdown-trigger">
                <div data-v-0ada1f42="" class="trigger with-credits">
                  <div data-v-0ada1f42="" class="avatar-container">
                    <img alt="" data-v-0ada1f42="" class="avatar" src={userInfo.avatar} /></div>
                  <div data-v-0ada1f42="" class="credits-number">
                    <span data-v-0ada1f42="">
                      {genCreditsNumber()}
                    </span>
                    <img alt="" data-v-0ada1f42="" class="icon" src="/_nuxt/credit.ef9f7b0f.svg" />
                  </div>
                </div>
              </div>
            </Dropdown>
          </>
        )}

      </div>
    </header>
  );
}