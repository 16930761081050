import Header from '@components/Header';
import VIP from '@components/VIP';
import Footer from '@pages/homepage/footer';

export default function Aboutus() {
  return (
    <>
      <Header />
      <div class="layout-content" data-v-7b9bb84e="">
        <section class="banner" data-v-458aab05="" style={{ marginTop: '60px' }}></section>
        <section class="profile-section" data-v-458aab05="">
          <div class="page-content" data-v-458aab05="" data-v-9f8a4cfc="">
            <div class="title-container" data-v-458aab05="">
              <h2 class="section-title" data-v-458aab05="">公司简介</h2>
              <h2 class="section-title-en" data-v-458aab05="">Company Profile</h2></div>
            <div class="profile-content" data-v-458aab05="">
              <p class="p1" data-v-458aab05="">
                <span data-v-458aab05="">惠州新之源文化传播有限公司</span>
                <span style={{ color: '#8e8c93' }} data-v-458aab05="">成立于 2018 年，是国内领先的企业营销设计云平台。集“创意供给、内容存储、在线编辑、营销协作、品牌资产管理云”五大功能于一体。对创意供应链和设计服务流程整合，建立了以云端图像处理引擎为核心、涉及版权采买-设计交付-营销分发-数据分析的服务体系，提供企业营销设计一站式解决方案。</span></p>
              </div>
          </div>
        </section>
        <section data-v-458aab05="">
          <div class="page-content end-section-content" data-v-458aab05="" data-v-9f8a4cfc="">
            <h2 class="section-title" data-v-458aab05="">联系我们</h2>
            <h2 class="section-title-en" data-v-458aab05="">Contact Us</h2>
            <div class="content" data-v-458aab05="">
              <img src="/_nuxt/hands-shake.dac34c79.webp" class="img" data-v-458aab05="" />
              <div class="texts" data-v-458aab05="">
                <img src="/_nuxt/logo_cn.2218c6ed.png" class="logo" data-v-458aab05="" />
                <div class="inner" data-v-458aab05="">
                  <div data-v-458aab05="">
                    <p data-v-458aab05="">服务热线：13261811919</p>
                    <p data-v-458aab05="">公司地址：惠州仲恺高新区陈江街道金茂路北一街25号207</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}