import { useQuery } from 'react-query';
import qs from 'qs';
import { order } from '@services/user';
import { useState, useEffect } from 'react';
import { PRICES } from '@components/VIP/const';
import { getUserInfoServiceForWX } from '@services/user';
import { Avatar, Card, Result } from 'antd';
import styles from './index.module.scss';

export default function WXPay() {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { data, error } = useQuery('order', () => order(query), { skip: typeof WeixinJSBridge == 'undefined' });
  const { data: userInfo } = useQuery('getUserInfoServiceForWX', () => getUserInfoServiceForWX(query.openid), { skip: typeof WeixinJSBridge == 'undefined' });
  const [status, setStatus] = useState('pending');

  useEffect(() => {
    if (error) setStatus('error');
    if (!data) return;
    if (typeof WeixinJSBridge == 'undefined') return;
    const timestamp = `${parseInt(new Date().getTime() / 1000)}`;
    const params = {
      "appId": "wx4c802a6b882e4e2f",     //公众号ID，由商户传入     
      "nonceStr": CryptoJS.MD5(timestamp).toString().toUpperCase(), //随机串
      "package": `prepay_id=${data.data.prepay_id}`,
      "signType": "MD5",         //微信签名方式
      "timeStamp": timestamp,         //时间戳，自1970年以来的秒数
    }
    const str = `appId=${params.appId}&nonceStr=${params.nonceStr}&package=${params.package}&signType=${params.signType}&timeStamp=${params.timeStamp}&key=B5hEkbUYLsOkLeBNN3BmAt731ExSa9fn`;
    const paySign = CryptoJS.MD5(str).toString().toUpperCase();
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
      ...params,
      "paySign": paySign //微信签名
    },
      function (res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          setStatus('success');
        } else {
          setStatus('cancel');
        }
      });
  }, [data, error]);

  if (!query.openid || !query.t || !query.s) {
    return <Result status="error" title="参数错误" />;
  }

  const price = PRICES.filter((item) => item.s == query.s && item.t == query.t && item.d == query.d);
  if (price.length < 1) {
    return <Result status="error" title="参数错误" />;
  }

  if (typeof WeixinJSBridge == 'undefined') {
    return <Result status="error" title="请使用微信扫码打开本页面" />;
  }

  if (status == 'success') {
    return <Result status="success" title="支付成功" />;
  }

  if (status == 'cancel') {
    return <Result status="404" title="支付取消" />;
  }

  if (status == 'error') {
    return (
      <Result status="error" title="下单失败" subTitle="可能的原因如下">
        <ul>
          <li>1. 您的时长VIP尚未过期，您充值了次数VIP</li>
        </ul>
      </Result>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.avatar}>
          <Avatar size={100} src={userInfo?.data?.avatar}/>
        </div>
        <Card title="订单信息">
          <div className={styles.l}>
            充值类型：<b>{price[0].l1 + 'VIP会员'}</b>
          </div>
          <div className={styles.l}>
            应付金额：<b>{price[0].l2}</b>
          </div>
          <div className={styles.l}>
            原价：<b>{price[0].l3}</b>
          </div>
        </Card>
      </div>
    </>
  );
}