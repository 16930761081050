import Header from '@components/Header';
import VIP from '@components/VIP';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function License() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.href = '/index.3b49210b.css';
    document.head.appendChild(style);
    setTimeout(() => setLoading(false), 500);
    return () => style.remove();
  }, []);

  if (loading) return null;

  return (
    <>
      <Header mute={true} />
      <VIP />
      <main data-v-50ade72f="" data-v-3469ecbf="" class="main">
        <div data-v-50ade72f="" class="body">
          <div data-v-50ade72f="" class="content layout-content">
            <div data-v-3469ecbf="" class="inner">
              <ul data-v-1f6b9767="" data-v-3469ecbf="" class="menus" style={{ marginRight: '28px' }}>
                <Link to="/help/license">
                  <li data-v-1f6b9767="" class="menu-item current">
                    <span data-v-1f6b9767="">授权许可协议</span>
                  </li>
                </Link>
                <Link to="/help/privacy-policy">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">隐私政策</span>
                  </li>
                </Link>
                <Link to="/help/user-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">用户协议</span>
                  </li>
                </Link>
                <Link to="/help/vip-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">VIP 会员协议</span>
                  </li>
                </Link>
                <Link to="/help/ai-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">AI 服务协议</span>
                  </li>
                </Link>
              </ul>
              <section data-v-37857998="" data-v-3469ecbf="" class="common-wrapper">
                <p>
                  <strong>授权许可协议</strong>
                  <strong></strong>
                </p>
                <p>为保障您更好地使用PPAI提供的正版内容，PPAI与您就正版内容的授权达成本协议，请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的以及其他涉及您重大权益的条款，这些条款将以加黑的方式提示您重点注意。您成为PPAI会员或在PPAI上购买、下载正版内容即视为您已了解并完全自愿接受本授权许可协议的各项内容。</p>
                <p>
                  <strong>一、定义</strong></p>
                <p>1、PPAI：指惠州新之源文化传播有限公司及其运营的官方网站www.PPAI.com系本协议的授权方。</p>
                <p>2、会员：指享受PPAI会员服务的自然人、法人、非法人组织，系本协议中的被授权方，在本协议中更多的称呼为“您”。</p>
                <p>3、会员服务：指PPAI为会员提供的多元化网络增值服务，具体服务内容以PPAI提供为准。</p>
                <p>4、内容提供方：指与PPAI共同为会员提供正版内容的主体。</p>
                <p>5、正版素材：指内置于PPAI的设计编辑器的素材或设计模板的组成素材，包括但不限于电子、数字或其他格式的图片、音乐、字体等。但PPAI提示有版权风险的素材不属于正版素材。</p>
                <p>6、设计模板：指PPAI提供的，具有独创性及设计美感的，可供用户编辑制作成PPT设计成品的作品。</p>
                <p>8、正版内容：指设计模板与正版素材的统称。除正版内容外，经PPAI检测后提示有版权风险的内容，请被授权方替换或自行联系权利人获取授权。</p>
                <p>9、设计成品：指用户在PPAI上利用多项/类正版内容创作并下载/分享完成的最终成品，单个正版素材不视为也不得作为设计成品。</p>
                <p>11、个人使用：指为个人学习、研究或欣赏目的而使用正版内容。</p>
                <p>12、公益使用：指为了宣传、推广实际发生的公益活动而使用正版内容。公益活动系长期性地、持续性地、非营利性质地通过捐赠财物、时间、精力、知识等赞助或支持某项社会公益事业，如抗击疫情、救助灾害等。</p>
                <p>13、商业使用：指为商业目的而使用的设计成品，包括个人商业使用和企业商业使用，具体商业使用范围详见本协议第三条中的授权范围，但会员在商业使用中需遵守相关的授权限制。</p>
                <p>14、个人商业使用：指自然人主体（持自然人有效身份证）将设计成品用于以自然人为主体从事的商业盈利行为，最终受益主体也为个人。</p>
                <p>15、企业商业使用：指企业、个体工商户、行政组织、社会团体等法人及非法人组织将设计成品用于自身的商业盈利行为。</p>
                <p>
                  <strong>二、知识产权声明</strong></p>
                <p>1、PPAI上展示的正版内容知识产权归属于PPAI或内容提供方，并受中华人民共和国法律法规及相应国际条约保护。</p>
                <p>2、您有权按照PPAI的授权许可，在全球范围及授权范围内，对正版内容制作的设计成品享有非排他性的、非独占的、不可转让的、不可转许可的仅限个人商业使用权利。</p>
                <p>3、 PPAI依据本协议对您的授权许可，不视为对正版内容的知识产权、所有权等其他任何权利的转让，除本协议明确陈述的授权外，您不享有正版内容的其他任何权利。</p>
                <p>4、您同意并理解，您基于正版内容创作了相同或相似的设计成品，或是在设计成品中使用正版内容的部分或全部，正版内容的知识产权及所有权利仍归属PPAI及内容提供方。</p>
                <p>5、PPAI上除正版内容外，还存在部分未显示标识的内容或经提示有风险的内容，您需要替换上述内容或自行联系权利人获取授权。</p>
                <p>
                  <strong>三、授权范围</strong></p>
                <p>根据您所购买的PPAI会员服务，您享受的授权范围（PPAI将根据市场行情及正版内容的版权变动情况更新、调整正版内容及授权范围）。</p>
                <p>1.用户</p>
                <p>免费用户仅支持个人学习、研究或欣赏及公益使用，不得用于商业使用行为。</p>
                <p>2.会员用户</p>
                <p>“您购买的会员”仅限自然人主体（持自然人有效身份证）订购，您订购会员后，您于会员服务期内使用会员可用正版内容制作并下载的设计成品中，可在如下范围内用于以自然人为主体从事的商业盈利行为，您需保证盈利及最终的受益主体均为个人：</p>
                <table border="0" cellspacing="2">
                  <tbody>
                    <tr>
                      <td width="69" valign="center" nowrap="">
                        <p align="center">
                          <strong>授权范围</strong>
                          <strong></strong>
                        </p>
                      </td>
                      <td width="91" valign="center">
                        <p align="center">
                          <strong>授权用途</strong>
                          <strong></strong>
                        </p>
                      </td>
                      <td width="247" valign="top">
                        <p align="center">
                          <strong>授权项</strong>
                          <strong></strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="69" valign="center" nowrap="" rowspan="2">
                        <p align="center">新媒体用途</p></td>
                      <td width="91" valign="center">
                        <p>新媒体配图</p>
                      </td>
                      <td width="247" valign="top">
                        <p>微博、微信、头条、抖音等新媒体账号文章配图 短视频等</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="91" valign="center">
                        <p>网站及应用程序</p>
                      </td>
                      <td width="247" valign="top">
                        <p>设计用图、企业营销、APP设计、微信小程序配图、皮肤/主题、装修模版类用图（非转售用途）</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="69" valign="center" nowrap="" rowspan="3">
                        <p align="center">线上用途
                          <br/>（标准）</p></td>
                      <td width="91" valign="center">
                        <p>电商用途</p>
                      </td>
                      <td width="247" valign="top">
                        <p>淘宝、京东、天猫、头条等第三方电商平台用图</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="91" valign="center">
                        <p>网络推广</p>
                      </td>
                      <td width="247" valign="top">
                        <p>百度推广、360推广、淘宝直通车等</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="91" valign="center">
                        <p>网络视频用途</p>
                      </td>
                      <td width="247" valign="top">
                        <p>直播、短视频、快手、抖音、微视、视频号等第三方短视频平台</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="69" valign="center" nowrap="" rowspan="3">
                        <p align="center">线下用途
                          <br/>（扩展）</p></td>
                      <td width="91" valign="center">
                        <p>商业提案（非转售）</p>
                      </td>
                      <td width="247" valign="top">
                        <p>网络设计、VI设计、项目营销策划等提案</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="91" valign="center">
                        <p>印刷用途</p>
                      </td>
                      <td width="247" valign="top">
                        <p>宣传册、画册、折页、海报、门店陈设、易拉宝等</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="91" valign="center">
                        <p>图书出版</p>
                      </td>
                      <td width="247" valign="top">
                        <p>报纸配图、杂志封面及配图、图书封面及配图</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>请注意：如设计成品中包含您自行添加的内容，或者包含PPAI提示须由您自行获得授权的内容，则您在使用设计成品前需确保已获得上述内容的必要授权，否则该设计成品将视为未获得授权。</p>
                <p>
                  <strong>四、授权限制</strong></p>
                <p>1、本协议项下的授权仅对PPAI网站提供的正版内容有效，其他任何途径的内容及其生成的设计成品均不受本协议的保护。</p>
                <p>2、本授权仅限您自身使用，您不得将本授权或将正版内容、设计成品等用于向第三方授权、转让、共享、租售等；不得允许正版内容、设计成品以任何形式（包括独立文件形式）被第三方下载、提取、解压缩、再分销；也不得将正版内容、设计成品用于PPAI的竞争业务中。</p>
                <p>3、您不得违反或超出本协议的授权使用正版内容及设计成品，包括不得超出授权范围、授权权限、授权时间等。</p>
                <p>4、正版素材不得脱离设计成品单独商用，仅能作为设计成品的组成部分使用，且不得将单一正版素材作为设计成品的唯一组成部分。</p>
                <p>5、本协议的授权仅限于在PPAI的设计编辑器内编辑并生成的设计成品。您制作并下载设计成品后，在PPAI的设计模板编辑器外对设计成品进行增添、删减等任何修改行为，PPAI概不负责。</p>
                <p>6、您不得违反法律法规使用正版内容或设计成品，包括但不限于不得将正版内容用于欺骗性广告、不正当竞争以及任何侵犯他人合法权益的情形。</p>
                <p>7、您不得将正版内容及设计成品用于烟草、酒精、药物、整容、医疗保健产品等敏感行业的广告或推广项目。</p>
                <p>8、您不得对正版内容及其中的人物肖像（如有）以冒犯、侵权、违法等不当方式使用，不当方式包括但不限于：（1）在色情图像或类似情形中使用；（2）用于暗示模特参与不道德或非法行为；（3）用于暗示模特遭受身体或精神衰弱、疾病等状况;（4）用于政治性代言的行为；（5）其他对人物有冒犯或贬低的行为。</p>
                <p>9、您不得将正版内容制成转售商品，如模板、壁纸、手机壳、贺卡、明信片、日历、杯子、T恤等。</p>
                <p>10、您不得将正版内容用于楼宇、车身、灯箱、橱窗等户外广告。</p>
                <p>11、您不得将正版内容用于任何商标、商号、商业名称、外观设计专利、产品包装、服务标志或标识等。</p>
                <p>12、如您对上述第9、10、11项有特殊需求，可联系PPAI进行专属定制。</p>
                <p>
                  <strong>五、双方责任</strong></p>
                <p>1、您应当充分理解本协议中各项条款，承诺并保证有权利签订本协议并有能力履行本协议的义务，否则您无权使用企业PPAI的任何内容。</p>
                <p>2、因您对授权内容违法违规或违约修改、使用等所导致您自身的损失由您自行承担；给企业PPAI或第三方造成的损害和损失，由您全部赔偿，包括但不限于直接损失、间接损失、商誉损害、律师费用、法律开支等一切维护权益的费用。企业PPAI无须对您和第三方承担任何责任，并且，有权在不予通知的情况下关停您的平台账号，且不予退回您已付任何费用（如有）。</p>
                <p>3、PPAI尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用PPAI上传的文字、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，PPAI有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行承担全部法律责任；如因您的侵权行为导致PPAI及其关联公司遭受损失（包括但不限于经济、商誉等损失），您还应足额赔偿PPAI及其关联公司遭受的全部损失。</p>
                <p>4、 您严格依照本协议约定的方式使用PPAI提供的正版内容，且没有违反与PPAI签订的任何其他协议，如遭受第三方指控侵犯著作权的，PPAI将为您提供必要的法律协助。您因此承担赔偿责任或其他不利法律后果的，有权就您遭受的直接损失（不包括可得利益损失），在本协议约定的赔偿额度范围内向PPAI申请补偿，PPAI承担由内容引起的法律责任的最大限额为：在法律允许的范围内，您向PPAI支付的费用（如会员费用、产品或服务费用、授权费用）的壹拾（10）倍，如果在PPAI通知或者您获悉任何内容被指控侵犯第三方权益后，您继续使用该内容的，不适用本赔偿保证。</p>
                <p>5、您理解并同意，如PPAI获悉任何内容可能被指控侵犯任何第三方的权利，PPAI有权要求您立即停止使用该内容、删除或销毁任何复制件以及确保您的客户、分销商和/或雇主同样采取上述行动，因此引起的费用由您自行承担。在符合本协议其他条款的前提下，PPAI将免费为您提供同等价位、同等类型的替换内容。</p>
                <p>6、双方同意：除本协议第五条双方责任中第4条约定外，在任何情况下，PPAI对您使用或无法使用PPAI或内容本身而产生的或与此相关的赔偿或补偿责任，累计不得超过PPAI实际从您处收到的授权费用（如会员费用、产品或服务费用、授权费用）。</p>
                <p>7、对于因本协议引起的任何利润损失、惩罚性、特殊、间接、后果性、附带或其他类似损害、费用或损失，PPAI无需对被授权人或任何其他人或实体承担责任，即便PPAI已被告知可能发生该等损害、费用或损失。</p>
                <p>8、您理解并同意，PPAI将尽全力丰富平台可体验的正版内容，但由于正版内容的版权变动等原因，PPAI将变更、下架部分内容，您将无法在PPAI上再次编辑使用已变更、下架的正版内容。您于正版内容变更、下架前下载完成的设计成品可以继续使用、生成分享链接的可以继续分享，但您的上述使用、分享等仍应遵循本协议。因前述原因造成您无法使用部分正版内容的，PPAI不承担任何责任且无需退还已收取的费用。</p>
                <p>
                  <strong>六、法律适用及争议解决</strong>
                  <strong></strong>
                </p>
                <p>1、本授权书的订立、履行及解释均适用中华人民共和国法律。</p>
                <p>2、因履行本授权书而产生的任何纠纷，双方应协商友好解决，如无法通过友好协商的方式解决，任一方均有权向惠州新之源文化传播有限公司所在地有管辖权的人民法院起诉。</p>
                <p>
                  <strong>七、其他条款</strong>
                  <strong></strong>
                </p>
                <p>1、本协议自您下载或使用正版内容之日或您购买PPAI会员服务之日起生效。</p>
                <p>2、如您在会员服务期限内升级，您需要重新填写授权书，您的商用授权以最新填写的授权书为准。同时，升级前的授权书在您会员升级成功时自动终止，您升级会员后的授权范围以升级后的授权书为准。</p>
                <p>3、本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。</p>
                <p>4、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
                <p>5、本协议为PPAI用户服务协议、隐私政策、会员服务协议等其他协议、规则的组成部分，本协议未做约定的以相关协议内容为准。</p>
                <p>6、PPAI有权依据国家政策、业务发展、产品功能等变化需要而进行修改本协议，并将修改后的协议予以发布。您可在PPAI的最新版本中查阅变更相关协议条款。本协议条款变更后，如果您继续使用PPAI，即视为您已接受修改后的协议。</p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
