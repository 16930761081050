import Header from '@components/Header';
import VIP from '@components/VIP';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function AI() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.href = '/index.3b49210b.css';
    document.head.appendChild(style);
    setTimeout(() => setLoading(false), 500);
    return () => style.remove();
  }, []);

  if (loading) return null;

  return (
    <>
      <Header mute={true} />
      <VIP />
      <main data-v-50ade72f="" data-v-3469ecbf="" class="main">
        <div data-v-50ade72f="" class="body">
          <div data-v-50ade72f="" class="content layout-content">
            <div data-v-3469ecbf="" class="inner">
              <ul data-v-1f6b9767="" data-v-3469ecbf="" class="menus" style={{ marginRight: '28px' }}>
                <Link to="/help/license">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">授权许可协议</span>
                  </li>
                </Link>
                <Link to="/help/privacy-policy">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">隐私政策</span>
                  </li>
                </Link>
                <Link to="/help/user-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">用户协议</span>
                  </li>
                </Link>
                <Link to="/help/vip-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">VIP 会员协议</span>
                  </li>
                </Link>
                <Link to="/help/ai-agreement">
                  <li data-v-1f6b9767="" class="menu-item current">
                    <span data-v-1f6b9767="">AI 服务协议</span>
                  </li>
                </Link>
              </ul>
              <section data-v-37857998="" data-v-3469ecbf="" class="common-wrapper">
                <p>
                  <strong>AI服务协议</strong>
                  <strong></strong>
                  <strong></strong>
                </p>
                <p>
                  <strong>特别提示：</strong>
                  <strong></strong>
                </p>
                <p>本协议系由用户与惠州新之源文化传播有限公司旗下PPAI平台中的产品和服务所订立的权利义务规范。</p>
                <p>本服务为PPAI平台产品及服务的组成部分，除非您阅读并点击同意本协议以及《用户协议》、《会员服务协议》、《授权许可协议》的所有条款，否则您无权登录PPAI服务账号（以下简称“账号”）及使用任何PPAI AI服务（以下简称“AI服务”）。您通过PPAI任一产品及服务平台登录、点击开启以及在开启本服务状态下进行内容上传、生成及复制转载等使用本服务的行为即视为您同意前述协议的全部条款，并同意受其约束。</p>
                <p>本协议中的重要条款，特别是免除、限制责任的条款，PPAI已采用加粗字体来特别提醒您，在使用本服务前请您认真查阅。</p>
                <p>本协议中您认为有违网络安全规范、涉及侵犯个人隐私及用户权益的，可以向网站客服进行投诉反馈。</p>
                <p>
                  <strong>使用规范</strong>
                  <strong></strong>
                </p>
                <p>1. 本服务是PPAI基于第三方的大数据和技术模型服务而向自然人用户（以下简称“用户”）提供的AI合成服务，服务主要包括内容生成、续写、优化以及后续可能推出并纳入AI服务范畴内的其他各项服务，具体服务以我们实际提供的为准。 您在中国大陆地区以外使用本服务应遵守您所在地国家或地区的全部法律、法规及政策的限制与监督。</p>
                <p>2. 用户通过PPAI产品及服务平台（包括但不限于PPAI享有运营权利的官方应用软件、网站、H5页面、小程序、公众号等，具体可使用平台以PPAI实际提供的为准）内置AI功能使用本服务，每个PPAI账号初始具备免费体验次数，具体次数以PPAI实际提供的为准。您应对通过账号使用本服务的一切行为（包括但不限于发布、生成、续写、优化信息、同意协议、规则等）承担全部责任；同时，您不得以任何形式转让、授权他人使用您的账号中所提供的本服务。PPAI后续可能变更服务方式，具体以实际提供的为准。</p>
                <p>3. 本服务仅可供您自行通过登录账号在PPAI产品及服务平台（包括但不限于PPAI享有运营权利的官方应用软件、网站、H5页面、小程序、公众号等）中使用，您在任何时候均无权以任何方式将本服务包含的任何部分或全部予以转让、转授权、出租、借用、分享给您以外的任何一方。您访问或使用本服务的方式不应侵犯PPAI及任何第三方的权利。</p>
                <p>4. 您理解并同意，PPAI产品的AI服务为增值服务，除PPAI免费赠送的体验次数外，后续使用本服务需要开通PPAI账号会员，收费标准以《会员服务协议》的约定及PPAI产品及服务平台实时结算的付费金额为准。对于收费服务，PPAI会在您使用之前给予您明确的提示，只有您根据提示确认其愿意支付相关费用，您才能使用含本AI增值服务的会员账号增值服务；如您拒绝支付相关费用，则无法使用含本AI增值服务的会员账号增值服务。除上述收费服务外，与相关网络服务有关的设备（如个人电脑、其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行负担，PPAI不就用户未支付该等费用导致无法接入本服务等影响服务使用的情形承担任何责任。</p>
                <p>5. 您在本服务中生成的人工智能生成内容所依赖的大数据和技术模型由第三方提供，我们完全遵守接入第三方技术接口的协议及规范；但我们无法完全保证第三方能够长期持续稳定地在本服务项下向您提供某些特定技术/服务，当我们与第三方调整合作方式，或变更合作第三方时，您可能无法继续以原有方式使用或继续使用该等第三方服务。同时，您充分知晓并理解，由于人工智能创作作品的法律法规仍在发展变化中，如您使用本服务遭受损失，在正式生效法律法规规定PPAI承担相应责任前，推定系由于第三方提供的技术/服务所致，您只能向该第三方追索赔偿，PPAI将不承担任何责任。</p>
                <p>
                  <strong>内容限制</strong>
                  <strong></strong>
                </p>
                <p>1. 您在使用本服务时输入的内容不得侵害他人权益（包括但不限于他人知识产权、肖像权、名誉权和个人隐私与其他权益），您应对使用本服务时自行输入的内容承担全部责任。</p>
                <p>2. 您同意，将严格依照本协议及《用户协议》、《会员服务协议》使用本服务，并且不会利用本服务进行任何违法活动，输入或复制转载含有下列内容之一的信息：</p>
                <p>（1） 反对宪法所确定的基本原则的信息；</p>
                <p>（2） 违反国家规定的政治宣传信息；</p>
                <p>（3） 涉及国家秘密和/或安全、危害国家安全、颠覆国家政权、破坏国家统一的信息；</p>
                <p>（4） 损害国家荣誉和利益的信息；</p>
                <p>（5） 封建迷信、邪教和/或淫秽、色情、暴力、凶杀、恐怖的信息或教唆犯罪的信息；</p>
                <p>（6） 博彩游奖、赌博游戏、“私服”、“外挂”等非法出版信息；</p>
                <p>（7） 违反国家民族和宗教政策的信息，煽动民族仇恨、民族歧视、破坏民族团结的；</p>
                <p>（8） 妨碍区块链运行安全的信息；</p>
                <p>（9） 侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容；</p>
                <p>（10） 其他违反法律法规、部门规章、国家政策及公序良俗、社会公德等的内容。</p>
                <p>3. 您应当对使用本服务输入的内容是否符合法律法规规定进行必要审查，并由您承担由此产生的所有责任。您确保您提供的内容（包括但不限于自定义输入的文本或上传的图片等）不含政治相关及违反法律法规的内容，亦未侵害他人合法权益或已获得了充分、必要且有效的合法许可及授权。</p>
                <p>4. PPAI依据本协议、《用户协议》及《会员服务协议》保护所有PPAI用户的个人隐私，但PPAI不就您使用本服务时输入的内容进行内容审查，因此您需自行确认使用本服务时输入的内容将不包含任何保密、敏感或个人隐私等信息，且您确认您的输入内容将不被认为是您的个人隐私资料。</p>
                <p>
                  <strong>使用限制</strong>
                  <strong></strong>
                </p>
                <p>1. 您充分理解使用本服务的先决条件为注册并登录PPAI服务账号，您在注册及使用时应遵守《用户协议》中的注册及使用规则，妥善保管好您的账号、密码。</p>
                <p>2. 您发现本服务生成的内容违反“内容行为规范”的，有权在本服务内容生成页面上点击“内容举报/投诉”来进行投诉。</p>
                <p>3. 我们发现或收到他人举报或投诉用户违反本协议任何约定的，我们有权不经通知随时对相关内容进行处理，并视行为情节有权中止或终止提供相关服务，包括但不限于：无法使用本服务及账号封禁等。</p>
                <p>4、本服务可能会对不同用户提供相同的反馈内容，您知悉并认可，您对这些内容并不享有独占权利（如著作权），您不得限制其他用户对相关反馈内容的使用。</p>
                <p>
                  <strong>免责条款</strong>
                  <strong></strong>
                </p>
                <p>1. 您知悉并认可，由于本服务生成的所有内容均由人工智能模型生成，则：</p>
                <p>（1） 受限于现有技术水平，我们无法保证生成内容的准确性、完整性、时效性、可用性，生成内容亦不可避免的会出现事实、观点、价值等方面的瑕疵，我们对此不承担任何法律责任，但我们会尽可能督促第三方技术提供方为您提供稳定优质的技术服务。如果您在使用本服务的过程中遇到令您不快或与法律法规相冲突的情形，您可点击“内容举报/投诉”进行反馈，我们会在核实后立刻处理。</p>
                <p>（2） 生成内容仅供参考，不代表PPAI的态度、观点或立场，亦不构成PPAI对您的任何建议或意见。如涉及医疗、法律、金融投资相关等专业领域事项，请咨询专业人士。算法回答可能与您实际情况或现实生活不符，您应审慎判断其真实性、可靠性。</p>
                <p>2. 本服务所依赖的第三方技术模型服务的训练数据包括但不限于公开互联网的信息，其中有部分信息可能会存在一定的敏感性、不合理性或引发理解歧义，如您将生成的敏感性内容或不理性及有理解歧义的内容进行公开传播，您将独自承担相应的法律责任。</p>
                <p>
                  <strong>知识产权</strong>
                  <strong></strong>
                </p>
                <p>1. 我们在本服务中提供的自有内容（包括但不限于PPAI平台、技术、程序、代码、用户界面、网页、文字、图片、图像、音频、视频、图表、版面设计、商标、电子文档等）的知识产权属于PPAI所有，未经PPAI书面许可，您不得以任何形式使用或创造相关衍生作品。您亦不得通过任何机器人、“蜘蛛”等程序或设备来监视、复制PPAI平台及本服务提供的自有内容，或利用PPAI平台及本服务提供的自有内容申请知识产权登记、二次开发、开展竞争业务等。</p>
                <p>2. 您不得对本服务中的任何内容进行反向编译、反汇编等任何形式的反向工程或绕过任意服务的技术限制。对于任何计费机制（包括计量用户对服务使用情况的任何机制），您不得通过篡改或以其他方式尝试规避。</p>
                <p>3. 您理解并承诺，您在使用本服务时发布上传的内容（包括但不限于文字、图片、视频、音频等各种形式的内容及其中包含的音乐、声音、台词、视觉设计等所有组成部分，或相关数据）均由您原创或已获得相关权利人的合法授权（含转授权），不侵犯任何人的合法权益（包括但不限于知识产权、名誉权、姓名权、肖像权、隐私权、商业秘密等），否则由此产生的纠纷或风险由您自行承担。</p>
                <p>4. PPAI对本服务开发、运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。</p>
                <p>
                  <strong>个人信息保护</strong>
                  <strong></strong>
                </p>
                <p>PPAI非常重视保护用户信息。在您使用本服务的过程中，我们会收集和使用您的账号信息及操作行为信息（包括但不限于输入内容、点击“内容举报/投诉”等），用于向您提供AI服务；如我们共享您的个人信息至合作伙伴，除严格遵守“合法正当、最小必要、目的明确原则”外，我们亦将从技术保护措施、合作协议层面，严格要求合作伙伴履行对您个人信息的保护义务与责任。</p>
                <p>
                  <strong>违约责任</strong>
                  <strong></strong>
                </p>
                <p>用户同意保障和维护PPAI及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给PPAI或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</p>
                <p>
                  <strong>争议管辖</strong>
                  <strong></strong>
                </p>
                <p>1. 本服务条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本服务条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。</p>
                <p>2. 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时任何一方均可向成都市高新区人民法院提起诉讼。</p>
                <p>
                  <strong>协议效力</strong>
                  <strong></strong>
                </p>
                <p>1. 本协议是您与我们之间关于您使用本服务所达成的法律协议，本协议生效日期以协议首部载明的更新日期为准。与此同时，本协议自您勾选“同意”（具体措辞以页面载明的为准）后对双方产生约束力，具体时间以我们系统记录的时间为准。</p>
                <p>2. PPAI可能根据实际运营情况对本协议内容进行变更，PPAI也可能会对本服务目前提供的免费部分开始收费，前述协议变更前，PPAI将在相应服务页面进行通知或公告，该等内容一经公布即视为通知用户。如您继续使用PPAI提供的服务，则视为您已经接受本协议的全部修改。如您不同意条款修改，则无权使用本协议的相关内容。</p>
                <p>3. 本协议视为《用户协议》、《会员服务协议》、《授权许可协议》的补充协议，是其不可分割的组成部分，与其构成统一整体，本协议未涉及的内容以《用户协议》、《会员服务协议》、《授权许可协议》为准，《用户协议》、《会员服务协议》、《授权许可协议》未涉及的内容以及本协议内容与《用户协议》、《会员服务协议》、《授权许可协议》内容存在冲突、不同的，以本协议的最新的更新版本（如有）的约定为准。</p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
