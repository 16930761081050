import instance from './axios';

export const qrcode = async () => {
  const res = await instance({
    url: '/wx/qrcode',
    method: 'get'
  });
  return res;
};

export const checkLogin = async (uuid) => {
  const res = await instance({
    url: '/wx/check_login',
    method: 'get',
    params: { uuid },
  });
  return res;
};

export const login = async ({ mobile, password }) => {
  const res = await instance({
    url: '/auth/login',
    method: 'post',
    data: { mobile, password },
  });
  return res;
};

export const loginRefresh = async () => {
  const res = await instance({
    url: '/auth/refresh',
    method: 'post',
  });
  return res;
};

export const logout = async () => {
    const res = await instance({
      url: '/auth/logout',
      method: 'post',
    });
    return res;
};