import Header from '@components/Header';
import VIP from '@components/VIP';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function User() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.href = '/index.3b49210b.css';
    document.head.appendChild(style);
    setTimeout(() => setLoading(false), 500);
    return () => style.remove();
  }, []);

  if (loading) return null;

  return (
    <>
      <Header mute={true} />
      <VIP />
      <main data-v-50ade72f="" data-v-3469ecbf="" class="main">
        <div data-v-50ade72f="" class="body">
          <div data-v-50ade72f="" class="content layout-content">
            <div data-v-3469ecbf="" class="inner">
              <ul data-v-1f6b9767="" data-v-3469ecbf="" class="menus" style={{ marginRight: '28px' }}>
                <Link to="/help/license">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">授权许可协议</span>
                  </li>
                </Link>
                <Link to="/help/privacy-policy">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">隐私政策</span>
                  </li>
                </Link>
                <Link to="/help/user-agreement">
                  <li data-v-1f6b9767="" class="menu-item current">
                    <span data-v-1f6b9767="">用户协议</span>
                  </li>
                </Link>
                <Link to="/help/vip-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">VIP 会员协议</span>
                  </li>
                </Link>
                <Link to="/help/ai-agreement">
                  <li data-v-1f6b9767="" class="menu-item">
                    <span data-v-1f6b9767="">AI 服务协议</span>
                  </li>
                </Link>
              </ul>
              <section data-v-37857998="" data-v-3469ecbf="" class="common-wrapper">
                <p>
                  <strong>用户协议</strong>
                  <strong></strong>
                </p>
                <p>PPAI 是一款智能生成演示幻灯片的在线工具，为保障您更好的使用PPAI平台及服务，请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的以及其他涉及您重大权益的条款，这些条款可能以加黑方式提示您重点注意。如果您未满18周岁，请在法定监护人的陪同下完成或由法定监护人代理完成。您在本软件上注册、登录、使用即视为您已充分阅读并完全自愿接受本用户协议的各项内容，包括PPAI对本协议随时所做的任何修改，并成为PPAI用户。本协议在您与PPAI之间产生法律效力，成为对双方均具有约束力的法律文件。
                  <br/></p>
                <p>
                  <strong>一、服务说明</strong>
                  <strong></strong>
                </p>
                <p>1. 服务宗旨：助你轻松完成PPT设计。</p>
                <p>2. 服务主体：本服务由PPAI为您提供。"PPAI"是惠州新之源文化传播有限公司及其关联方（包括但不限于北京微梦传媒股份有限公司）运营的网络平台，本协议项下的"PPAI"根据不同的语境可能指PPAI网页、移动端及围绕PPAI软件产品提供的相关技术服务。"用户"是指注册、登录、使用PPAI及服务的使用人，在本协议中更多地称呼为"您"。</p>
                <p>3. 服务内容：PPAI 是一款智能生成演示幻灯片的在线工具，将为您提供包括但不限于各类图片、素材、模板、以及在线编辑功能等。您无需花费太多时间在无意义的格式设置上，输入标题即可轻松生成完整的PPT文档。</p>
                <p>4. 服务平台：PPAI提供本软件或服务包括但不限于web端、移动端多个应用版本，域名为www.PPAI.com的网站，以及多种类小程序，让您可以随时随地完成PPT制作。PPAI也会不断进行更新和改进，以满足您日益增长的需求。</p>
                <p>5. 服务许可范围：</p>
                <p>·PPAI赋予您一项非排他性的、非独家的、不可转让的、不可转许可的授权，以使用本软件。</p>
                <p>·本协议未明示授权的其他一切权利仍由PPAI保留，您在行使这些权利时须另外取得PPAI的书面许可。PPAI未行使或执行本协议任何权利或规定，不构成对该权利的放弃。</p>
                <p>
                  <strong>二、关于“账号”</strong>
                  <strong></strong>
                </p>
                <p>1. 账号注册</p>
                <p>
                  <br/>1.1 您在使用PPAI各项服务前需提供个人真实信息完成账号注册和密码设置，或通过PPAI认可的第三方账号进行注册，为保证您享用的平台服务安全有效且不断优化，您同意授权PPAI对您的个人信息进行验证和合理使用。</p>
                <p>1.2 您注册成功的账号密码仅可供您一人正当使用，不应用于违法违规的用途、不应用于损害他方利益、不应恶意批量注册、不应将其透露、出借、赠与或转让给他人等，否则您须对不当使用负责。</p>
                <p>1.3 同一用户只能注册并绑定一个账号。同一手机号码、第三方账号，满足任一条件均视为同一用户。</p>
                <p>1.4 您需对您提供的注册资料的真实性、准确性、合法性负责，并及时更新您的资料。若您提供任何违法、不道德或PPAI认为不适合在PPAI上展示的资料，或是PPAI有理由怀疑你的资料属于程序或恶意操作，PPAI有权暂停或终止您的帐号，并拒绝您于现在和未来使用本服务之全部或任何部分。</p>
                <p>1.5 PPAI无须对用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、正确性、完整性、适用性及/或是否为最新资料的责任。</p>
                <p>2. 用户帐号安全</p>
                <p>完成本服务的注册程序并成功注册之后，您可使用您的手机号和密码，登录到您在PPAI的帐号。保护您的帐号安全，是您的责任。您同意：</p>
                <p>·您应对所有使用您的密码及帐号的活动负完全的责任，包括但不限于您在PPAI中进行的任何数据修改、款项支付、图片设计等行为。您应高度重视对账号与密码的保密，在任何情况下不向他人透露账号及密码。您的PPAI帐号遭到未获授权的使用，或者发生其它任何安全问题时，您将立即通知PPAI。</p>
                <p>·如果您未保管好自己的帐号和密码，因此而产生的任何损失或损害，PPAI无法也不承担任何责任。</p>
                <p>·您有责任维护个人账号、密码的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任。如果您未保管好自己的帐号和密码而对您、PPAI或第三方造成的损害，您将负全部责任。</p>
                <p>
                  <strong>三、用户信息保护及授权</strong>
                  <strong></strong>
                </p>
                <p>1. 您知悉并同意，为方便您使用本平台相关服务，本平台将按照本协议及《隐私政策》的规定收集、使用、存储和分享您的个人信息与必要信息，包括但不限于您的登记资料、作图记录、作图结果等。除法律法规规定、《隐私政策》约定或另有约定的情形外，未经您的许可PPAI不会获取、存储或向第三方公开、透露您的个人信息。</p>
                <p>2. 您知悉并确认，您在注册账号或使用本平台的过程中，需要提供真实的身份信息，PPAI将根据国家法律法规的要求，采用邮箱、移动电话等进行真实身份信息认证。若您提供的信息不真实、不完整，则无法使用本平台或在使用过程中受到限制，由此产生的不利后果，由您自行承担。</p>
                <p>3. PPAI将运用各种安全技术和程序、建立完善的管理制度来保护您的个人信息，防止您的信息被不当使用或遭受未经授权的访问、使用或披露。</p>
                <p>4. 未经您的同意，PPAI不会向其他任何公司、组织和个人披露您的个人信息，除非：</p>
                <ul>
                  <li>相关法律法规或司法机关、行政机关要求；</li>
                  <li>为完成合并、分立、收购或资产转让而转移；</li>
                  <li>为提供您要求的服务所必需；</li>
                  <li>依照《隐私政策》或其他协议规则可以转移或披露给第三方的情形。</li>
                  <li>PPAI非常重视对未成年人的信息保护，若您不具备完全民事行为能力，在使用PPAI前，应事先取得您的监护人的同意。
                    <br/></li></ul>
                <p>
                  <strong>四、用户行为规范</strong>
                  <strong></strong>
                </p>
                <p>1. 信息内容规范</p>
                <p>您应对使用PPAI平台及服务发布信息内容的真实性、合法性负责。如需获得该信息内容权利人或任何有权第三方的事先授权或批准的，应事先获得该等授权或批准；一旦您发布某项信息内容，将被视为您已事先获得该等授权或批准。</p>
                <p>1.1 本条所述信息内容是指用户利用PPAI制作、下载、使用、发布、传播的任何内容，包括但不限于用户的昵称、头像、用户说明等注册信息，或您使用PPAI生成的文字、图片、音频、视频等，以及其他使用PPAI所产生的内容。</p>
                <p>1.2 您理解并同意，PPAI一直致力于为用户提供文明健康、规范有序的网络环境，您不得利用PPAI制作、复制、发布、传播任何违法违规内容，包括但不限于：</p>
                <ul>
                  <li>反对宪法所确定的基本原则的；</li>
                  <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一，损害国家荣誉和利益的；</li>
                  <li>煽动民族仇恨、民族歧视、破坏民族团结的；</li>
                  <li>破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                  <li>散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
                  <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
                  <li>侮辱或者诽谤他人，侵害他人合法权利的；</li>
                  <li>含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</li>
                  <li>侵犯他人隐私、个人信息、著作权、商标权等知识产权或合法权利的内容；</li>
                  <li>散布烟草、酒精、药物、整容、医疗保健品类的商业广告，或类似的商业招揽信息，散布过度营销信息及垃圾信息；</li>
                  <li>含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容或含有PPAI认为不适合展示的内容。</li></ul>
                <p>1.3 您理解并同意，PPAI旨在推出多元化、多样式、多种类字体、素材供您使用，但您在体验过程中，仍需遵守法律规定及网站约定。</p>
                <p>1.3.1 若您将从PPAI平台下载的图片、模板、文字等内容用于广告内容，不得有下列情形：</p>
                <ul>
                  <li>使用或者变相使用中华人民共和国的国旗、国歌、国徽，军旗、军歌、军徽；</li>
                  <li>使用或者变相使用国家机关、国家机关工作人员的名义或者形象；</li>
                  <li>使用"国家级"、"最高级"、"最佳"等用语；</li>
                  <li>其他法律法规规定不得用于广告的情形。</li></ul>
                <p>1.3.2 若您将从PPAI平台上下载的图片、模板、文字等内容用于商标、企业名称、商品名称或logo等，不得有下列情形：</p>
                <ul>
                  <li>使用或者变相使用中华人民共和国、外国、政府间国际组织的国旗、国歌、国徽，军旗、军歌、军徽、勋章；</li>
                  <li>使用或变相使用中央国家机关的名称、标志、所在地特定地点的名称或者标志性建筑物的名称、图形；</li>
                  <li>使用县级以上行政区划的地名或公众知晓的外国地名；</li>
                  <li>使用PPAI或字体提供方禁止用于本条用途的字体、字库；</li>
                  <li>其他法律法规规定不得用于本条用途的情形。</li></ul>
                <p>1.3.3 若PPAI为您提供的图片中已包含国旗、国徽、军旗、军徽等，您禁止以任何方式毁损、玷污、涂划、践踏，否则依法追究法律责任由您自行承担。</p>
                <p>1.3.4 若PPAI为您提供的模板中包含人物肖像，您不得以冒犯或任何不道德方式使用模板中的人物肖像，如色情、整容等诽谤或带有其他非法、不道德内容的用途。</p>
                <p>1.3.5 PPAI将在电商类模板中使用电商平台LOGO、品牌标识等，以方便电商类用户使用，若您不是该电商平台的电商类用户或您未参加LOGO、品牌标识对应的电商活动，您不得使用上述素材、模板，若您必须要使用，须将LOGO、品牌标识删除，否则引发的责任和损失由您自行承担。</p>
                <p>1.3.6 非经PPAI书面允许，您不得将PPAI上的图片、模板、文字、LOGO等注册成商标或申请外观设计专利，如您有此需求，您可以联系PPAI进行专属定制或获得专门授权。</p>
                <p>2. 软件使用规范</p>
                <p>您同意按照本协议约定的方式合理合法地使用PPAI，除非法律允许或PPAI书面许可，您通过PPAI观看、分享、下载、使用、传播、发布的任何图片、视频及其他内容，仅限您个人使用，您不得有偿或无偿转让依本协议条款所取得的用户权利，且不得从事下列行为：</p>
                <ul>
                  <li>使用未经PPAI提供或认可的第三方软件、插件、外挂、系统、设备等登录或使用本服务。</li>
                  <li>对PPAI拥有知识产权的内容进行销售、出租、出借、复制、修改、链接、转载、汇编、发表、出版等；</li>
                  <li>对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；</li>
                  <li>未经合法授权而截获、篡改、收集、储存或删除他人个人信息、站内邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的；</li>
                  <li>对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经PPAI授权的第三方工具/服务接入本软件和相关系统；</li>
                  <li>通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</li>
                  <li>通过非PPAI开发、授权的第三方软件、插件、外挂、系统，登录或使用PPAI平台及服务，或制作、发布、传播上述工具；</li>
                  <li>自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；</li>
                  <li>使用任何方式（包括但不限于第三方软件、插件、外挂、系统、设备等）对PPAI进行干扰、破坏、修改或实施其他任何危害网络安全的活动；</li>
                  <li>其他违反法律法规、政策或未经PPAI明示授权的行为；</li></ul>
                <p>除本协议约定外，PPAI还将在服务平台内以各种形式提醒用户规范使用PPAI提供的各项服务，您须遵守。您已认可PPAI未对用户的使用行为进行全面控制，您使用任何内容时，包括依赖前述内容之正确性、完整性或合法性时，您同意将自行加以判断并承担所有风险，PPAI在法律允许的范围内不对您因前述风险而导致的任何损失或损害承担责任。在任何情况下，若PPAI有理由认为您的任何行为违反或可能违反上述约定的，PPAI可独立进行判断并处理，且有权在未经通知的情况下终止向您提供服务，并追究相关责任。若您违反本协议约定使用PPAI提供的服务而被第三方追责的，由您自行承担全部责任。
                  <br/></p>
                <p>
                  <strong>五、违约处理</strong>
                  <strong></strong>
                </p>
                <p>1. 如果PPAI发现或收到他人举报投诉您违反本协议或存在任何恶意行为的，PPAI有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号除以包括但不限于警告、限制或禁止使用全部或部分功能、账号封禁、注销等处罚，并公告处理结果。PPAI有权对涉嫌违反法律法规、涉嫌违法犯罪的行为保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。对已删除内容公司有权不予返还。</p>
                <p>2. 因您违反本协议或其他条款规定，引起第三方投诉或诉讼索赔的，您应当自行承担全部法律责任。因您的违法或违约行为导致PPAI及其关联公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿PPAI及其关联公司因此遭受的全部损失。</p>
                <p>3. 公司尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用PPAI上传的文字、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，PPAI有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行承担全部法律责任；如因您的侵权行为导致PPAI及其关联公司遭受损失的（包括但不限于经济、商誉等损失），您还应足额赔偿PPAI及其关联公司遭受的全部损失。</p>
                <p>
                  <strong>六、服务的中断和终止</strong>
                  <strong></strong>
                </p>
                <p>1. 您理解并同意，PPAI有权于任何时间暂时或永久修改或终止本服务（或其任何部分），且无论通知与否。您同意对于本服务所作的任何修改、暂停或终止，PPAI对您和任何第三人均无需承担任何责任，您应妥善做好数据更新、备份与转移工作。</p>
                <p>2. 因您违反本协议，PPAI有权根据实际情况中断或终止其中一项或多项服务，严重违反的，PPAI将直接终止您对PPAI的使用权。</p>
                <p>3. 您对本协议内容有异议，或对PPAI提供的服务不满意，可行使如下权利：</p>
                <ul>
                  <li>停止使用PPAI账号服务；</li>
                  <li>自行注销或通知PPAI注销您的账号。账号注销将导致您在PPAI上的个人信息、作图记录，设计细节等一并删除，您和PPAI相应的权利义务随之终止（您的任何损害赔偿义务不会因此而终止），PPAI不承担传送任何未处理的信息或未完成的服务给您的责任。
                    <br/>
                      <strong></strong></li>
                </ul>
                <p>
                  <strong>七、第三方产品及服务</strong></p>
                <p>1. 您在使用本软件中由第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。如因您使用由第三方提供的产品或服务时引发的任何纠纷，应由您和第三方负责解决，PPAI不承担任何责任。</p>
                <p>2. 您在使用本软件中由第三方提供的产品或服务时，本软件可能会调用第三方系统或者通过第三方支持用户的使用或访问，使用或访问的结果由该第三方提供，PPAI不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，由此引发的任何争议及损害，与PPAI无关，PPAI不承担任何责任。
                  <br/></p>
                <p>
                  <strong>八、广告</strong>
                  <strong></strong>
                </p>
                <p>1. PPAI中可能包含广告，这属于PPAI及其关联公司合理合法的商业模式，您同意在使用PPAI过程中接收由PPAI及其关联公司或者其第三方合作伙伴发布的广告信息或其他信息（包括商业与非商业信息），信息的具体发送及展示形式、频次及内容等以PPAI实际提供为准。</p>
                <p>2. 您通过本网站与广告商及其他第三方进行任何形式的通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及达成的其它任何相关条款、条件、保证或声明，完全为您与广告商及其他第三方之间之行为。您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因前述任何交易或前述广告商及其他第三方而遭受的任何性质的损失或损害，PPAI对此不负任何法律责任。
                  <br/></p>
                <p>
                  <strong>九、增值服务</strong>
                  <strong></strong>
                </p>
                <p>1. PPAI可能会对部分服务开始收费，也可能在将来采取付费模式，并根据实际需要对付费模式的收费标准、方式进行调整与完善。如您使用收费服务，请遵守相关的协议。若您不同意相关协议及其修改和变更，请暂停使用增值服务。若您为未满18周岁的未成年人，请您在法定监护人陪同下，认真仔细阅读增值服务相关协议并选择是否接受该协议及其服务。</p>
                <p>2. 在PPAI降低增值服务的收费标准或者将收费服务改为免费服务提供时，PPAI保留不对原付费用户提供退费或者费用调整的权利。
                  <br/></p>
                <p>
                  <strong>十、免责声明</strong>
                  <strong></strong>
                </p>
                <p>1. 您理解并同意，PPAI软件及相关服务可能会受多种因素的影响或干扰，因此PPAI不保证(包括但不限于)：</p>
                <ul>
                  <li>完全适合用户的使用要求；</li>
                  <li>不受干扰，及时、安全、可靠或不出现错误；用户经由PPAI取得的任何软件、服务或其他材料符合用户的期望；</li>
                  <li>软件中任何错误都将能得到更正。</li></ul>
                <p>2. 对于涉嫌借款或其他涉财产的网络信息、账户密码、广告或推广等信息的，请您谨慎对待并自行进行判断，基于前述原因您因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，公司不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。</p>
                <p>3. 您理解并同意，在使用PPAI中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力给用户造成的损失，用户同意公司不承担责任。</p>
                <p>4. PPAI将依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。</p>
                <p>5. 您明确了解并同意：关于本协议服务，PPAI不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对本协议软件及相关服务的使用行为必须自行承担相应风险。</p>
                <p>6. 您明确了解并同意，您使用本服务之风险由您个人负担，包括但不限于因任何资料之下载而导致的您手机之任何损坏或数据流失；您将文字、资讯、资料、音乐、照片、图形、视讯、信息或其它个人资料上传到互联网上，而被其他组织或个人复制、转载、或做其它用途等。</p>
                <p>7. 您明确了解并同意，本服务仅依其当前所呈现的状况提供，对于任何用户信息或个人化设定之时效、删除、传递错误、未予储存或其它任何问题，PPAI均不承担任何责任。PPAI保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</p>
                <p>8. 您明确了解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，PPAI在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。
                  <br/></p>
                <p>
                  <strong>十一、PPAI的有限责任</strong>
                  <strong></strong>
                </p>
                <p>1. 您严格依照本协议约定的方式使用PPAI提供的内容，且没有违反与PPAI签订的任何其他协议，如遭受第三方指控侵犯著作权的，PPAI将为您提供必要的法律协助。您因此承担赔偿责任或其他不利法律后果的，有权就您遭受的直接损失（不包括可得利益损失），在本协议约定的赔偿额度范围内向PPAI申请补偿，PPAI承担由内容引起的法律责任的最大限额为：在法律允许的范围内，您向PPAI支付的费用（如会员费用）的壹拾（10）倍，如果在PPAI通知或者您获悉任何内容被指控侵犯第三方权益后，您继续使用该内容的，不适用本赔偿保证。</p>
                <p>2. 您明确了解并同意，PPAI无法审查和跟踪您使用上述内容的用途。据此，您超出本协议约定或PPAI授权的其他范围使用内容，导致的第三方追责或您自身的损失，由您自行承担。
                  <br/></p>
                <p>
                  <strong>十二、知识产权声明</strong>
                  <strong></strong>
                </p>
                <p>1. PPAI是本软件及服务的知识产权权利人。本软件的一切著作权、专利权、商标权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、界面设计、版面框架、电子文档等）均受中华人民共和国和相应的国际条约保护，PPAI享有上述知识产权，依法属于他人的除外。</p>
                <p>2. 未经PPAI书面同意，您不得为任何商业或非商业目的自行或许可第三方实施、利用、转让上述知识产权，PPAI保留追究上述行为法律责任的权利。</p>
                <p>3. 您理解并同意，您在使用PPAI平台及相关服务时发布上传的文字、图片、视频、音频等均由您原创或已获合法授权（含转授权）。您通过PPAI制作内容的知识产权归您或原始著作权人所有。</p>
                <p>4. 您同意授予PPAI一项为提供、优化和宣传推广本服务之目的所必需的免费、非独占、可在全球范围内收集、使用、存储、管理和保护您在本服务过程中产生的用户内容的权利。PPAI将根据 《隐私政策》并尽最大努力在合理范围内使用用户内容，且您的必要授权与同意并不代表PPAI对您在本服务过程中产生内容的必然使用，也不改变您对用户内容的所有权及其知识产权归属，更不影响您对用户内容任何合法使用。
                  <br/></p>
                <p>
                  <strong>十三、投诉方式及处理</strong>
                  <strong></strong>
                </p>
                <p>1. 如您发现PPAI平台内存在任何侵犯您权利的内容，请立即通过邮箱liuyang1@microdreams.com通知PPAI，提供您有相关权利的初步证据，PPAI将会根据法律规定及时处理您的投诉。如您在使用PPAI制作的内容被相关权利人投诉侵权，您也可以通过向liuyang1@microdreams.com提交反通知书及相关权利证明材料，来进行申诉，公司同样会根据法律规定及时处理您的申诉。</p>
                <p>2. PPAI倡议您共同参与维护及监督PPAI使用环境的合规与清洁。无论PPAI公司主动发现您的不当、违法行为，或者是PPAI根据举报、投诉并初步查实您存在不当、违法行为，PPAI均有权立即在无事先通知的情况下采取必要的措施以制止该等行为的继续进行。
                  <br/></p>
                <p>
                  <strong>十四、其他条款</strong>
                  <strong></strong>
                </p>
                <p>1. 您登录、使用PPAI即视为您已阅读并同意受本协议的约束。</p>
                <p>2. PPAI有权依据国家政策、技术条件、产品功能等变化需要而进行修改本协议，并将修改后的协议予以发布。您可在PPAI的最新版本中查阅更相关协议条款。本协议条款变更后，如果您继续使用PPAI，即视为您已接受修改后的协议。</p>
                <p>本协议内容同时包括PPAI已经发布及后续可能不断发布的相关协议、规则等内容（包括《隐私政策》）。前述内容已经发布，公司将以适当的方式（网站公布、系统通知等）提醒您及时阅读。前述相关协议、规则构成本协议不可分割的组成部分，您应同样遵守。</p>
                <p>3. 除非本协议另有其它明示规定，PPAI增加或强化目前本任何新功能，包括所推出的新产品，均受到本使用协议之规范。</p>
                <p>4. 本协议的成立、生效、履行、解释及争议解决均适用中华人民共和国的法律。</p>
                <p>5. 本协议的签署地点为中华人民共和国北京市朝阳区，若您与PPAI发生争议，应尽量友好协商解决，协商不成，您同意将该争议提交至惠州新之源文化传播有限公司住所地人民法院管辖。</p>
                <p>6. 本协议及所有条款之标题仅供方便而设，不具任何法律或契约效果。</p>
                <p>7. PPAI对于您所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行（联系方式以您在平台预留的为准）；该等通知于发送之日视为已送达收件人。</p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
