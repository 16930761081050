import bus from '@utils/bus';
import { EVENTS } from '@constants/bus';

export const goHomepage = () => {
  let path = window.location.pathname;
  if (path === '/') {
    history.go(0);
    return;
  }
  window.location.href = '/';
}

export const logout = async () => {
  window.localStorage.removeItem("UUID");
}

export const hasLogin = () => {
  return window.localStorage.getItem('UUID')?.length > 0;
}

export const goWorkspace = () => {
  if (!hasLogin()) {
    bus.emit(EVENTS.LOGIN);
  } else {
    window.location.href = '/workspace';
  }
}

export const login = () => {
  if (!hasLogin()) {
    bus.emit(EVENTS.LOGIN);
  }
}