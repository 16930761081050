import { useRoutes } from 'react-router-dom';
import Index from '@pages/index';
import AI from '@pages/help/ai';
import License from '@pages/help/license';
import Privacy from '@pages/help/privacy';
import User from '@pages/help/user';
import VIP from '@pages/help/vip';
import WXPay from '@pages/wxpay';
import Aboutus from '@pages/aboutus';

const routes = [
  {
    path: '',
    element: <Index />,
  },
  {
    path: '/about-us',
    element: <Aboutus />,
  },
  {
    path: '/help/license',
    element: <License />,
  },
  {
    path: '/help/privacy-policy',
    element: <Privacy />,
  },
  {
    path: '/help/user-agreement',
    element: <User />,
  },
  {
    path: '/help/vip-agreement',
    element: <VIP />,
  },
  {
    path: '/help/ai-agreement',
    element: <AI />,
  },
  {
    path: '/wxpay',
    element: <WXPay />
  },
  {
    path: '*',
    element: <Index />,
  },
];


const AppRoutes = () => {
  const element = useRoutes(routes);
  return element;
}

export default AppRoutes;
